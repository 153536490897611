import { createReducer, on } from '@ngrx/store';
import { viewNewccConstantVariable } from './newccConstanst.action';
import { saveNewccConstantVariable } from './newccConstanst.action';

export const initialState = false;

export const newccConstantReducer = createReducer(
	initialState,
	on(viewNewccConstantVariable, state => {
		return state;
	}),
	on(saveNewccConstantVariable, (state, { payload }) => {
		state = payload;
		return state;
	}),
);
