import { Component, OnInit, Inject, AfterViewInit, Renderer2, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Router, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from 'src/app/shared/auth/authentication.service';

@Component({
	selector: 'app-user-layout',
	templateUrl: './user-layout.component.html',
	styleUrls: ['./user-layout.component.sass'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class UserLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
	configSub: Subscription;

	public innerWidth: any;

	overlayContent = false;

	constructor(private router: Router, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private _auth: AuthService) {
		this.innerWidth = window.innerWidth;
	}

	ngOnInit() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(routeChange => {
			if (this.innerWidth < 1200) {
				this.overlayContent = false;
				this.renderer.removeClass(this.document.body, 'overflow-hidden');
			} else {
				this.overlayContent = true;
			}
		});
	}

	ngAfterViewInit() {}

	ngOnDestroy() {
		//Unsubcribe subscriptions
		if (this.configSub) {
			this.configSub.unsubscribe();
		}
	}

	logout() {
		this._auth.logout();
	}
}
