import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/authentication.service';

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.sass'],
})
export class MaintenanceComponent implements OnInit {
	constructor(private authService: AuthService) {}

	ngOnInit(): void {}
}
