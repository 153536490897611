<div nz-row class="tabs-step-back">
	<div nz-col nzSpan="24" [ngClass]="{ 'content-step-back-mobile': !_isViewportDesktop, 'content-step-back-desktop': _isViewportDesktop }">
		<button nz-button nzType="text" class="button-step-back" (click)="goto()">
			<span nz-icon nzType="ng-zorro:arrowLeft" nzTheme="outline"></span>
		</button>
		<div class="label">
			<span nz-typography class="label-step-back">{{ _label }}</span>
		</div>
		<div class="last-item" [ngClass]="{'mr-cus-24': _isViewportDesktop}">
			<button *ngIf="_canDeleteVisible && _isViewportDesktop" nz-button nzType="text" class="button-delete label-14 mr-cus-12" (click)="deleteEvent()">
				<span nz-icon nzType="ng-zorro:trash" nzTheme="outline"></span>ลบ
			</button>
			<button *ngIf="_canDeleteVisible && !_isViewportDesktop" nz-button nzType="text" class="button-delete-mobile label-18" [nzSize]="'large'" (click)="deleteEvent()">
				<span  nz-icon nzType="ng-zorro:trash"></span>
			</button>
			<button
				*ngIf="_isSaveVisible"
				nz-button
				nzType="text"
				[ngClass]="{ 'button-save label-14': _canSave, 'button-save-disable label-14': !_canSave }"
				[disabled]="!_canSave"
				(click)="callback()">
				บันทึก
			</button>
			<button
				*ngIf="_isSaveNewccVisible"
				nz-button
				nzType="text"
				[ngClass]="{ 'button-save label-14': _canSave, 'button-save-disable ': !_canSave }"
				style="width: 100%"
				[disabled]="!_canSave"
				(click)="callback()">
				ยืนยันส่งงาน
			</button>
			
			
		</div>
	</div>
</div>
