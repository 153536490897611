import { createReducer, on } from '@ngrx/store';
import { actionGetTaskSummary, actionSaveTaskSummary } from './taskSummary.action';

export const initialState: any = [];

export const taskSummaryReducer = createReducer(
	initialState,
	on(actionGetTaskSummary, state => {
		return state;
	}),
	on(actionSaveTaskSummary, (state, { payload }) => {
		state = payload;
		return state;
	}),
);
