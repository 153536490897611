import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';

import { actionGetCollectionJsonBodyDetail, actionGetJsonBodyDetail, actionSaveJsonBodyDetail } from 'src/app/redux/jsonBodyDetail/jsonBodyDetail.action';

@Injectable()
export class JsonFacadeService {
	getJsonState = createFeatureSelector<any>('jsonBody');
	getJsonLoaded = createSelector(this.getJsonState, (state: any) => state);

	public loaded$: Observable<boolean> = this.store.pipe(select(this.getJsonLoaded));

	constructor(private store: Store<any>) {}

	public loadJson() {
		this.store.dispatch(actionGetCollectionJsonBodyDetail());
	}

	public addJson(accCallNo: string, jsonObj: any) {
		this.store.dispatch(
			actionGetJsonBodyDetail({
				accCallNo: accCallNo,
				payload: { ...jsonObj },
			}),
		);
	}
}
