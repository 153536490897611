import { Component, OnInit, Input, Output, EventEmitter, ErrorHandler } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageMethod, Method, MethodFunction } from 'src/app/shared/model/common/page-method';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { lastValueFrom, map, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment.localTest';
import { AuthService } from '../../auth/authentication.service';
import { first } from 'rxjs/operators';
import { ToolsService } from '../../service/tools/tools.service';

@Component({
	selector: 'app-document-video-upload',
	templateUrl: './document-video-upload.component.html',
	styleUrls: ['./document-video-upload.component.sass'],
})
export class DocumentVideoUploadComponent implements OnInit {
	@Input() _modalCameraVisible: boolean = false;
	@Input() _showModalCard: any = '';
	@Input() _radioValue: any = '';
	@Input() _currentTask: any = {};
	@Input() _fileInput: any = '';

	@Output() public _saveImage: EventEmitter<any> = new EventEmitter<any>();

	_pageMethod: PageMethod = { method: '', func: '' };
	modalTitle?: string;
	showimagecontainer = false;
	@Input() _showfile: boolean = false;

	fileUploadObject: any = [];
	UploadObject: any = '';

	cropImgSetSpanfront: any = '';
	cropImgSetOffsetfront: any = '';
	showfileupload = false;
	showjson = false;
	saveimage: any = '';
	imagevalue: any = 0;
	content: any = '';

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private message: NzMessageService,
		private breakpointObserver: BreakpointObserver,
		public authService: AuthService,
		private toolsService: ToolsService
	) {
		this._activatedRoute.queryParams.subscribe(params => {
			this._pageMethod.method = params.method || '';
			this._pageMethod.func = params.func || '';
		});
	}

	ngOnInit(): void { }

	closecroppedImagefront() { }

	async fileChangeEventWithName(file: any, name: string) {
		if (this._fileInput === 'doc') {
			const isImages = this.isImage(file.name);
			const isDoc = this.isDoc(file.name);
			if (isImages) {
				this.convertFile(file).subscribe(async base64 => {
					const srcPath = 'data:image/png;base64,' + base64;
					this.saveImage(srcPath, srcPath, name, file.type);
				});
				return true;
			} else if (isDoc) {
				const srcPath = URL.createObjectURL(file);
				this.saveImage(srcPath, srcPath, name, file.type);
				return true;
			} else {
				this.message.create('error', 'ประเภทไฟล์รูปภาพไม่ถูกต้อง กรุณาอัพโหลดรูปภาพใหม่อีกครั้ง');
				return false;
			}
		} else if (this._fileInput === 'video') {
			if (this.isImagevideo(file.name)) {
				this.convertFile(file).subscribe(async base64 => {
					const srcPath = `data:video/mp4;base64,${base64}`;
					this.saveImage(srcPath, srcPath, name, file.type);
				});
				return true;
			} else {
				this.message.create('error', 'ประเภทไฟล์วิดีโอไม่ถูกต้อง กรุณาอัพโหลดวิดีโอใหม่อีกครั้ง');
				return false;
			}
		}
		this.message.create('error', 'ประเภทไฟล์วิดีโอไม่ถูกต้อง กรุณาอัพโหลดวิดีโอใหม่อีกครั้ง');
		return false;
	}

	fileChangeEvent(event: any): void {
		if (this._fileInput === 'doc') {
			var selectedFiles = event.target.files;
			for (var i = 0; i < selectedFiles.length; i++) {
				if (this.isImagedoc(event.target.files[i].name) == true) {
					this.save2aws(event.target.files[i]);
				}
			}
		} else if (this._fileInput === 'video') {
			var selectedFiles = event.target.files;
			for (var i = 0; i < selectedFiles.length; i++) {
				if (this.isImagevideo(event.target.files[i].name) == true) {
					this.save2awsVideo(event.target.files[i]);
				}
			}
		}
	}

	isImage(filename: any) {
		var ext = this.toolsService.getExtension(filename);
		switch (ext.toLowerCase()) {
			case 'jpg':
			case 'gif':
			case 'bmp':
			case 'png':
			case 'jpeg':
				return true;
			default:
				return false;
		}
	}
	isDoc(filename: any) {
		var ext = this.toolsService.getExtension(filename);
		switch (ext.toLowerCase()) {
			case 'pdf':
				return true;
			default:
				return false;
		}
	}

	isImagedoc(filename: any) {
		var ext = this.toolsService.getExtension(filename);
		switch (ext.toLowerCase()) {
			case 'pdf':
			case 'jpg':
			case 'png':
			case 'jpeg':
				return true;
			default:
				return false;
		}
	}

	isImagevideo(filename: any) {
		var ext = this.toolsService.getExtension(filename);
		switch (ext.toLowerCase()) {
			case 'mp4':
			case 'mov':
				return true;
			default:
				return false;
		}
	}

	convertDataUrlToBlob(dataUrl: any): Blob {
		const arr = dataUrl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = window.atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	convertFile(file: File): Observable<string> {
		const result = new ReplaySubject<string>(1);
		const reader = new FileReader();
		reader.readAsBinaryString(file);
		reader.onload = event => result.next(window.btoa(reader.result?.toString() || ''));
		return result;
	}

	saveData2aws(data: any, type: string): Observable<HttpEvent<any>> {
		const fileName = data.name;
		const file = new File([data], fileName, { type: data.type });
		const tokenData = this.authService.currentTokenValue;
		const headerDirect = {
			Authorization: 'Bearer ' + tokenData.idToken,
		};

		const requestOptions = {
			headers: new HttpHeaders(headerDirect),
			reportProgress: true,
			observe: 'events',
		};
		const addressUrl = `${environment.endpointAws}/awsS3/uploadPicture`;
		let myFormData: any = new FormData();
		myFormData.append('file', file);
		myFormData.append('accCallNo', this._currentTask.AccCallNo);
		myFormData.append('fileName', fileName);

		return this.http.request(new HttpRequest('POST', addressUrl, myFormData, requestOptions));
	}

	save2aws(files: any) {
		const tokenData = this.authService.currentTokenValue;
		const headerDirect = {
			Authorization: 'Bearer ' + tokenData.idToken,
		};

		const requestOptions = {
			headers: new HttpHeaders(headerDirect),
		};
		const imageBlob = files;
		const filename = this._currentTask.AccCallNo + Math.floor(Math.random() * 100 + 1).toString();
		const file = new File([imageBlob], filename, { type: files.type });
		const addressUrl = `${environment.endpointAws}/awsS3/uploadPicture`;
		let myFormData: any = new FormData();
		myFormData.append('file', file);
		myFormData.append('AccCallNo', this._currentTask.AccCallNo);

		/* Image Post Request */
		this.http.post(addressUrl, myFormData, requestOptions).subscribe(async res => {
			//Check success message
			const awspic: any = res;
			const picurl = awspic.url;
			const picSignedUrl = await this.getObjectUrls(picurl);
			this.saveImage(picurl, picSignedUrl, filename, files.type);
		});
	}

	save2awsVideo(files: any) {
		const tokenData = this.authService.currentTokenValue;
		const headerDirect = {
			Authorization: 'Bearer ' + tokenData.idToken,
		};

		const requestOptions = {
			headers: new HttpHeaders(headerDirect),
		};
		const imageBlob = files;
		const filename = this._currentTask.AccCallNo + Math.floor(Math.random() * 100 + 1).toString();
		const file = new File([imageBlob], filename, { type: files.type });
		const addressUrl = `${environment.endpointAws}/awsS3/uploadVideo`;
		let myFormData: any = new FormData();
		myFormData.append('file', file);
		myFormData.append('AccCallNo', this._currentTask.AccCallNo);

		/* Image Post Request */
		this.http.post(addressUrl, myFormData, requestOptions).subscribe(async res => {
			//Check success message
			const awspic: any = res;
			const picurl = awspic.url;
			const picSignedUrl = await this.getObjectUrls(picurl);
			this.saveImage(picurl, picSignedUrl, filename, files.type);
		});
	}

	async getObjectUrls(url: any) {
		const tokenData = this.authService.currentTokenValue;
		const headerDirect = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + tokenData.idToken };
		const requestOptions = {
			headers: new HttpHeaders(headerDirect),
		};
		const addressUrl = `${environment.endpointAws}/awsS3/getObject`;
		const checkResultObservable = this.http.post(addressUrl, { urls: [url] }, requestOptions);
		const checkResult = await lastValueFrom(checkResultObservable);
		return checkResult.toString();
	}

	saveImage(url: any, signedUrl: any, imagename: any, type: string) {
		this.fileUploadObject = {
			id: Math.floor(Math.random() * 100 + 1).toString(),
			name: imagename,
			url: url,
			type: type,
			signedUrl: signedUrl,
		};
		this._saveImage.emit(this.fileUploadObject);
	}
}
