import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'src/app/shared/service/api/user.service';

import { BreakpointService } from 'src/app/shared/service/form-helper/breakpoint.service';

import { DOCUMENT } from '@angular/common';
import { ERROR_CODE } from '../../model/constanst';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.sass'],
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
	selectedIndex = 0;
	public forgetUserForm: FormGroup;
	public forgetUserFormOtp: FormGroup;
	public otpVerifyForm: FormGroup;
	isViewportDesktop = false;
	emailPreview: string = '';
	isLoadingSignOn = false;
	passwordVisible = false;
	loading = false;
	submitted = false;
	isAlreadySend = false;

	@ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
	messageError = '';
	sessionId = '';
	inputClass = 'otp-custom-class';
	otpConfig = {
		allowNumbersOnly: true,
		length: 6,
		inputStyles: {
			width: '48px',
			height: '48px',
		},
		inputClass: this.inputClass,
		placeholder: '0',
	};
	isRequestOtp = false;
	otpSubmitted = false;
	verifySubmitted = false;
	constructor(
		private _router: Router,
		private _toastrService: ToastrService,
		private _formBuilder: FormBuilder,
		private userService: UserService,
		private breakpointService: BreakpointService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
	) {}

	async ngOnInit() {
		this.breakpointService.isSmallScreen$.subscribe(isSmallScreen => {
			this.isViewportDesktop = !isSmallScreen;
		});

		this.forgetUserForm = this._formBuilder.group({
			email: ['', [Validators.required]],
		});

		this.forgetUserFormOtp = this._formBuilder.group({
			phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
		});
		this.otpVerifyForm = this._formBuilder.group({
			otp: ['', [Validators.required, Validators.minLength(6)]],
		});
	}

	get f() {
		return this.forgetUserForm.controls;
	}

	get fOtp() {
		return this.forgetUserFormOtp.controls;
	}

	get fOtpVerify() {
		return this.otpVerifyForm.controls;
	}

	async onSubmit() {
		try {
			this.submitted = true;
			this.loading = true;
			if (!this.forgetUserForm.invalid) {
				this.emailPreview = this.f.email.value + '@thaivivat.co.th';
				await this.userService.forgotPassword(this.emailPreview);
				this.isAlreadySend = true;
			}
		} catch (err: any) {
			this._toastrService.error(err.message, 'Request reset password fail');
		} finally {
			this.loading = false;
		}
	}

	returnToLogin() {
		this._router.navigate(['/pages/login']);
	}

	ngAfterViewInit() {
		if (this.document.getElementById('my-footer')) {
			this.document.getElementById('my-footer').style.display = 'none';
		}
	}

	handleIndexChange(e: number): void {
		this.submitted = false;
		this.otpSubmitted = false;
		this.selectedIndex = e;
	}

	async onSubmitRequestOtp() {
		try {
			this.otpSubmitted = true;
			this.loading = true;
			this.messageError = '';
			if (!this.forgetUserFormOtp.invalid) {
				const forgetPwdResp = await this.userService.forgotPasswordForSurveyor(this.fOtp.phone.value);
				this.sessionId = forgetPwdResp.sessionId;
				this.isRequestOtp = true;
			}
		} catch (err: any) {
			switch (err?.error?.message) {
				case ERROR_CODE.USER_NOT_FOUND:
					this.forgetUserFormOtp.get('phone').setErrors({ wrongauth: true });

					break;
				case ERROR_CODE.REQUEST_OTP_FOR_SURVEYOR_ONLY:
					this.forgetUserFormOtp.get('phone').setErrors({ surveyoronly: true });

					break;
				default:
					this.messageError = err?.error?.message;
					this.forgetUserFormOtp.get('phone').setErrors({ other: true });

					break;
			}
		} finally {
			this.loading = false;
		}
	}

	async onSubmitVerifyOtp() {
		try {
			this.verifySubmitted = true;
			this.loading = true;

			if (this.otpVerifyForm.invalid) {
				this.otpConfig = {
					...this.otpConfig,
					inputClass: 'otp-custom-class errors',
				};
			} else {
				const verifyOtpResp = await this.userService.verifyOtp(this.fOtp.phone.value, this.sessionId, this.fOtpVerify.otp.value);
				this._router.navigate(['/pages/reset-password'], {
					queryParams: { token: verifyOtpResp.token },
				});
			}
		} catch (err: any) {
			this.otpVerifyForm.reset();
			this.otpConfig = {
				...this.otpConfig,
				inputClass: 'otp-custom-class errors',
			};
			this.otpVerifyForm.get('otp').setErrors({ wrongauth: true });
		} finally {
			this.loading = false;
		}
	}

	gotoLogin() {
		this.router.navigate(['/']);
	}

	onOtpChange(e: any) {
		if (e === '') {
			this.otpConfig = {
				...this.otpConfig,
				inputClass: 'otp-custom-class',
			};
			this.verifySubmitted = false;
		}
	}

	onGoToInputNumber() {
		this.isRequestOtp = false;
		this.forgetUserFormOtp.reset();
		this.otpSubmitted = false;
		this.verifySubmitted = false;
		this.otpConfig = {
			...this.otpConfig,
			inputClass: 'otp-custom-class',
		};
	}
}
