<div class="card">
    <div class="card-header">
        <h2>ประกาศ</h2>
    </div>
    <div class="card-content" aria-expanded="true">
        <div class="card-body">
            <p class="card-text">กำลังทำการทดสอบ และ Implement ระบบ</p>
        </div>
    </div>
    <div class="card-footer">
        <span class="text-muted">Claim-on V.2.0</span>
    </div>
  </div>
          