<ng-container *ngIf="isAlreadySend === false">
    <nz-layout [ngClass]="{'main-layout-desktop': isViewportDesktop, 'main-layout-mobile': !isViewportDesktop}">
        <nz-content class="login-container">
            <div nz-row class="login-content-wrapper" [nzGutter]="16">
                <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="9"></div>
                <div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="10" [nzLg]="6">
                    <div>
                        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                            <div nz-row [nzGutter]="[0, 24]">
                                <div nz-col [nzSpan]="24" class="gutter-row">
                                    <img height="32px" src="/assets/img/tvi-claimon/claim-on-banner.png" alt="" />
                                </div>
                                <div nz-col [nzSpan]="24" class="gutter-row">
                                    <span class="header-login">สร้างรหัสผ่านใหม่</span>
                                     <p class="sub-header secondary mt-cus-8">รหัสผ่านใหม่ของคุณ ต้องมีความแตกต่าง จากรหัสที่เคยใช้ก่อนหน้านี้</p>
                                </div>
                               
                                <div nz-col [nzSpan]="24" class="gutter-row">
                                    <span nz-typography class="label-14">รหัสผ่านใหม่</span>
                                    <nz-input-group [nzSuffix]="inputPasswordTemplate" class="input-login" [ngClass]="{ 'btn-invalid': submitted && (f.password.errors || resetPasswordForm.errors) }">
                                        <input formControlName="password" [type]="isPasswordVisible ? 'text' : 'password'" nz-input placeholder=""  />
                                    </nz-input-group>
                                    <ng-template #inputPasswordTemplate>
                                        <span nz-typography><a class="tvi-colour" (click)="isPasswordVisible = !isPasswordVisible">{{isPasswordVisible? 'ซ่อน' : 'แสดง'}}</a></span>
                                    </ng-template>
                                    <span *ngIf="submitted && f.password.hasError('required')" nz-typography nzType="danger">กรุณาระบุรหัสผ่านใหม่</span>
                                    <span *ngIf="submitted && f.password.value && resetPasswordForm.errors?.['not_matching']" nz-typography nzType="danger">รหัสผ่านไม่ตรงกัน</span>
									<span *ngIf="submitted && f.password.value && !resetPasswordForm.errors && f.password.hasError('not_strong_pwd')" nz-typography nzType="danger">ต้องมีตัวอักษรตัวเลข และตัวอักษรพิเศษ [A-Z a-z 123 ~!@#$%^&*()_+] และจำนวนตัวอักษรต้องไม่น้อยกว่า 8 ตัว</span>
                                </div>
                                <div nz-col [nzSpan]="24" class="gutter-row">
                                    <span nz-typography class="label-14">ยืนยันรหัสผ่านใหม่</span>
                                    <nz-input-group [nzSuffix]="inputConfirmPasswordTemplate" class="input-login" [ngClass]="{ 'btn-invalid': submitted && (resetPasswordForm.errors || f.confirmPassword.errors || f.password.errors) }">
                                        <input formControlName="confirmPassword" [type]="isConfirmPasswordVisible ? 'text' : 'password'" nz-input placeholder="" />
                                    </nz-input-group>
                                    
                                    <ng-template #inputConfirmPasswordTemplate>
                                        <span nz-typography><a class="tvi-colour" (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible">{{isConfirmPasswordVisible? 'ซ่อน' : 'แสดง'}}</a></span>
                                    </ng-template>
                                    <span *ngIf="submitted && f.confirmPassword.hasError('required')" nz-typography nzType="danger">กรุณาระบุยืนยันรหัสผ่านใหม่</span>
                                    <span *ngIf="submitted && f.confirmPassword.value && resetPasswordForm.errors?.['not_matching']" nz-typography nzType="danger">รหัสผ่านไม่ตรงกัน</span>
									<span *ngIf="submitted && f.confirmPassword.value && !resetPasswordForm.errors && f.password.hasError('not_strong_pwd')" nz-typography nzType="danger">ต้องมีตัวอักษรตัวเลข และตัวอักษรพิเศษ [A-Z a-z 123 ~!@#$%^&*()_+] และจำนวนตัวอักษรต้องไม่น้อยกว่า 8 ตัว</span>
                                </div>
                                <div nz-col [nzSpan]="24" class="gutter-row">
                                    <button [disabled]="loading" nz-button nzType="primary" class="btn-signin tvi-colour" [nzLoading]="isLoadingSignOn">
                                        <span>ยืนยัน</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8">
                </div>
            </div>
        </nz-content>
    </nz-layout>
</ng-container>

<ng-container *ngIf="isAlreadySend === true">
    <ng-container *ngIf="isFailure === false">
        <nz-layout class="main-layout">
            <nz-header [ngClass]="{'login-header-desktop': isViewportDesktop, 'login-header-mobile': !isViewportDesktop}"></nz-header>
            <nz-content class="login-container">
                <div nz-row class="login-content-wrapper" [nzGutter]="16">
                    <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8">
                    </div>

                    <div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="12" [nzLg]="8">

                        <div nz-row [nzGutter]="[16, 10]">
                            <div nz-col nzSpan="24" class="set-center">
                                <img width="136px" height="112px" src="/assets/img/tvi-claimon/changepasswordsuccess.png" alt="" />
                            </div>
                            <div nz-col nzSpan="24" class="set-center">
                                <h1>เปลี่ยนรหัสผ่านสำเร็จ</h1>
                            </div>
                            <div nz-col nzSpan="24" class="set-center">
                                <p class="sub-header secondary">โปรดรอสักครู่ระบบจะนำคุณ<br />ไปยังหน้าลงชื่อเข้าใช้งาน</p>
                            </div>
                        </div>

                        <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8">
                        </div>
                    </div>
                </div>
            </nz-content>
        </nz-layout>
    </ng-container>
    <ng-container *ngIf="isFailure === true">
        <nz-layout class="main-layout">
            <nz-header [ngClass]="{'login-header-desktop': isViewportDesktop, 'login-header-mobile': !isViewportDesktop}"></nz-header>
            <nz-content class="login-container">
                <div nz-row class="login-content-wrapper" [nzGutter]="16">
                    <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8">
                    </div>

                    <div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="12" [nzLg]="8">

                        <div nz-row [nzGutter]="[16, 10]">
                            <div nz-col nzSpan="24" class="set-center">
                                <img width="136px" height="112px" src="/assets/img/tvi-claimon/Unlock_failed.png" alt="" />
                            </div>
                            <div nz-col nzSpan="24" class="set-center">
                                <h1>ไม่สามารถเปลี่ยนรหัสใหม่</h1>
                            </div>
                            <div nz-col nzSpan="24" class="set-center">
                                <p class="sub-header secondary">ลิงค์สำหรับเปลี่ยนรหัสผ่านของท่านได้หมดอายุ<br />หรือมีการใช้งานไปแล้ว</p>
                            </div>
                        </div>

                        <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8">
                        </div>
                    </div>
                </div>
            </nz-content>
        </nz-layout>
    </ng-container>
</ng-container>