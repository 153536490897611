import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-footer-save-json',
	templateUrl: './footer-save-json.component.html',
	styleUrls: ['./footer-save-json.component.sass'],
})
export class FooterSaveJsonComponent implements OnInit {
	@Input() _canSave?: boolean = true;

	@Output() _callback? = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	save() {
		this._callback.emit();
	}
}
