import { Component, OnInit, Input, Output, EventEmitter, ErrorHandler } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageMethod, Method, MethodFunction } from 'src/app/shared/model/common/page-method';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { lastValueFrom, map, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ObjOcrApiConfig } from 'src/app/shared/model/ocrConfig/apiConfig';
import { AuthService } from '../../auth/authentication.service';
import { environment } from 'src/environments/environment.localTest';
import { DriverLicenseTypes } from 'src/app/pages/user-pages/interfaces/user-pages.interface';
import { ToolsService } from '../../service/tools/tools.service';
@Component({
	selector: 'app-driver-licence',
	templateUrl: './driver-licence.component.html',
	styleUrls: ['./driver-licence.component.sass'],
})
export class DriverLicenceComponent implements OnInit {
	@Input() _modalCameraVisible: boolean = false;
	@Input() _modalCameraBackVisible: boolean = false;
	@Input() _radioValue: any = '';
	@Input() _currentTask: any = {};
	@Input() ocrToggle: boolean = false;

	@Output() public _saveImage: EventEmitter<any> = new EventEmitter<any>();
	@Output() public _saveImageBack: EventEmitter<any> = new EventEmitter<any>();
	@Output() public _sendCroppedImage: EventEmitter<object> = new EventEmitter<object>();

	_pageMethod: PageMethod = { method: '', func: '' };
	showimagecontainer = false;

	fileUploadObject: any = [];
	fileUploadObjectBack: any = [];
	UploadObject: any = '';
	showWebcam = false;
	JSONArr: any;
	valuenumberlicence?: string;
	valuetypelicence?: string;
	valueprefixlicence?: string;
	valuenamelicence?: string;
	objres: any = '';
	showCropper = false;
	showCropperback = false;
	Spinning = false;
	showswitch = false;
	croppedImage: any = '';
	webcamImage: any = '';
	webcamImageBack: any = '';
	webcamImagecrop: any = '';
	webcamImagecropBack: any = '';
	trigger: Subject<void> = new Subject<void>();
	triggerback: Subject<void> = new Subject<void>();
	nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
	nextWebcamback: Subject<boolean | string> = new Subject<boolean | string>();
	errors: WebcamInitError[] = [];
	imgcropped = false;
	imgcroppedBack = false;
	showcroppedImage = false;

	showWebcamBack = false;
	showbuttonBack = true;
	croppedImageBack: any = '';
	showcroppedImageBack = false;
	showimagecontainerBack = false;
	showfileupload = false;
	showfileuploadBack = false;
	aspectRatio: any = '';
	contentkey: any = '';
	showjson = false;
	Obj: any = ObjOcrApiConfig;
	awspic: any = '';
	awspicback: any = '';
	picSignedUrl: any = '';
	fileInfo: any = {
		front: {},
		back: {},
	};

	constructor(
		private _activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private message: NzMessageService,
		private authService: AuthService,
		private toolsService: ToolsService,
	) {
		this._activatedRoute.queryParams.subscribe(params => {
			this._pageMethod.method = params.method || '';
			this._pageMethod.func = params.func || '';
		});
	}

	ngOnInit(): void {
		this.JSONArr = <JSON>this.Obj;
	}

	showModallicenceCamera() {
		this._modalCameraVisible = true;
		this.showWebcam = true;
		this.showimagecontainer = true;
		this.showfileupload = false;
		this.aspectRatio = 2 / 3;
		this.showjson = false;
	}

	showModallicenceUpload() {
		this._modalCameraVisible = true;
		this.showWebcam = true;
		this.showimagecontainer = false;
		this.showfileupload = true;
		this.aspectRatio = 5 / 3;
		this.showjson = false;
	}

	handleOk(): void {
		this._modalCameraVisible = false;
		this.showimagecontainer = false;
		this.showswitch = true;
		this.showcroppedImage = true;
		this.showjson = false;

		if (this.croppedImage === '') {
			this._modalCameraVisible = false;
			this.showWebcam = false;
			this.webcamImagecrop = '';
			this.croppedImage = '';
			this.showswitch = false;
			this.showCropper = false;
			this.imgcropped = false;
			this.valuenumberlicence = '';
			this.valuetypelicence = '';
			this.valueprefixlicence = '';
			this.valuenamelicence = '';
			this.ocrToggle = false;
			this.showcroppedImage = false;
			this.Spinning = false;
			this.showjson = false;
		} else {
			const fileExt = this.toolsService.getExtension(this.fileInfo.front.name);
			const fileName: string = `${this.toolsService.makeRandom()}.${fileExt}`;
			const fullImagePathS3 = `${environment.awsS3BucketUrl}/${this._currentTask.AccCallNo}/${fileName}`;
			const signedUrl = this.croppedImage;
			this.saveImage(fullImagePathS3, signedUrl, fileName, this.fileInfo.front.type, DriverLicenseTypes.FRONT);
		}
	}

	handleCancel(): void {
		this._modalCameraVisible = false;
		this.showWebcam = false;
		// this.showbuttonfront = true;
		this.webcamImagecrop = '';
		this.croppedImage = '';
		this.showswitch = false;
		this.showCropper = false;
		this.imgcropped = false;
		this.showjson = false;
	}

	closecroppedImagefront() {
		this._modalCameraVisible = false;
		this.showWebcam = false;
		this.webcamImagecrop = '';
		this.croppedImage = '';
		this.showswitch = false;
		this.showCropper = false;
		this.imgcropped = false;
		this.valuenumberlicence = '';
		this.valuetypelicence = '';
		this.valueprefixlicence = '';
		this.valuenamelicence = '';
		this.ocrToggle = false;
		this.showcroppedImage = false;
		this.Spinning = false;
		this.showjson = false;
	}

	closecroppedImageBack() {
		this._modalCameraBackVisible = false;
		this.showWebcamBack = false;
		this.showbuttonBack = true;
		this.webcamImagecropBack = '';
		this.croppedImageBack = '';
		this.imgcroppedBack = false;
		this.showcroppedImageBack = false;
		this.Spinning = false;
		this.showjson = false;
		this.showCropperback = false;
	}
	// Web Cam Functions
	handleInitError(error: WebcamInitError) {
		this.errors.push(error);
	}

	handleImage(webcamImage: WebcamImage) {
		this.webcamImage = webcamImage;
		const canvas = document.getElementById('canvas') as HTMLCanvasElement;
		canvas.height = webcamImage.imageData.height;
		canvas.width = webcamImage.imageData.width;
		const ctx = canvas?.getContext('2d');
		// Draw image data to the canvas
		ctx?.putImageData(webcamImage.imageData, 0, 0);
		const dataURL = canvas.toDataURL();
		this.webcamImagecrop = dataURL;
		this.imgcropped = true;
		// this.rotate(webcamImage.imageAsDataUrl, 270, (resetBase64Image: any) => {
		//   this.webcamImagecrop = resetBase64Image;
		// });
		this.UploadObject = 1;
	}

	public get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	triggerSnapshot() {
		this.trigger.next();
		this.showimagecontainer = false;
	}

	public get nextWebcamObservable(): Observable<boolean | string> {
		return this.nextWebcam.asObservable();
	}

	imageCropped(event: ImageCroppedEvent) {
		if (this._radioValue === 'A') {
			this.rotate(event.base64, 270, (resetBase64Image: any) => {
				this.croppedImage = resetBase64Image;
			});
		} else {
			this.croppedImage = event.base64;
		}
	}

	imageOnload(degrees: number, canvas: HTMLCanvasElement, ctx: any, callback: any, image: any) {
		canvas.width = degrees % 180 === 0 ? image.width : image.height;
		canvas.height = degrees % 180 === 0 ? image.height : image.width;
		ctx?.translate(canvas.width / 2, canvas.height / 2);
		ctx?.rotate((degrees * Math.PI) / 180);
		ctx?.drawImage(image, image.width / -2, image.height / -2);
		ctx?.drawImage(image, image.width / -2, image.height / -2);
		callback(canvas.toDataURL());
	}

	rotate(srcBase64: any, degrees: any, callback: any) {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const image: any = new Image();
		image.onload = function () {
			canvas.width = degrees % 180 === 0 ? image.width : image.height;
			canvas.height = degrees % 180 === 0 ? image.height : image.width;
			ctx?.translate(canvas.width / 2, canvas.height / 2);
			ctx?.rotate((degrees * Math.PI) / 180);
			ctx?.drawImage(image, image.width / -2, image.height / -2);
			ctx?.drawImage(image, image.width / -2, image.height / -2);
			callback(canvas.toDataURL());
		};
		image.src = srcBase64;
	}

	imageLoaded() {
		this.showCropper = true;
	}

	showModallicenceCameraback(): void {
		this._modalCameraBackVisible = true;
		this.showWebcamBack = true;
		// this.showimagecontainerBack = true;
		// this.showbuttonBack = false;
		// this.showimagecontainerBack = true;
		this.showimagecontainerBack = true;
		this.showfileuploadBack = false;
		this.aspectRatio = 3 / 2;
		this.showjson = false;
	}

	showModallicenceUploadback(): void {
		this._modalCameraBackVisible = true;
		this.showWebcamBack = true;
		this.showimagecontainerBack = false;
		this.showfileuploadBack = true;
		this.aspectRatio = 5 / 3;
		this.showjson = false;
	}

	handleOkBack(): void {
		this._modalCameraBackVisible = false;
		this.showimagecontainerBack = false;
		this.showbuttonBack = false;
		this.showcroppedImageBack = true;
		this.showjson = false;
		if (this.croppedImageBack === '') {
			this._modalCameraBackVisible = false;
			this.showWebcamBack = false;
			this.showbuttonBack = true;
			this.webcamImagecropBack = '';
			this.croppedImageBack = '';
			this.imgcroppedBack = false;
			this.showcroppedImageBack = false;
			this.Spinning = false;
			this.showjson = false;
			this.showCropperback = false;
		} else {
			const fileExt = this.toolsService.getExtension(this.fileInfo.back.name);
			const fileName: string = `${this.toolsService.makeRandom()}.${fileExt}`;
			const fullImagePathS3 = `${environment.awsS3BucketUrl}/${this._currentTask.AccCallNo}/${fileName}`;
			const signedUrl = this.croppedImageBack;
			this.saveImage(fullImagePathS3, signedUrl, fileName, this.fileInfo.back.type, DriverLicenseTypes.BACK);
		}
	}

	handleCancelBack(): void {
		this._modalCameraBackVisible = false;
		this.showWebcamBack = false;
		this.showbuttonBack = true;
		this.webcamImagecropBack = '';
		this.croppedImageBack = '';
		this.imgcroppedBack = false;
		this.showjson = false;
		this.showCropperback = false;
	}

	// Web Cam Functions
	handleImageBack(webcamImageBack: WebcamImage) {
		this.webcamImageBack = webcamImageBack;
		// this.webcamImagecropBack = webcamImageBack.imageAsDataUrl;
		const canvasback = document.getElementById('canvasback') as HTMLCanvasElement;
		canvasback.height = webcamImageBack.imageData.height;
		canvasback.width = webcamImageBack.imageData.width;
		const ctx = canvasback?.getContext('2d');
		// Draw image data to the canvas
		ctx?.putImageData(webcamImageBack.imageData, 0, 0);
		const dataURL = canvasback.toDataURL();
		this.webcamImagecropBack = dataURL;
		this.imgcroppedBack = true;
		this.UploadObject = 1;
	}

	public get triggerObservableBack(): Observable<void> {
		return this.triggerback.asObservable();
	}

	triggerSnapshotBack() {
		this.triggerback.next();
		this.showimagecontainerBack = false;
	}

	public get nextWebcamObservableback(): Observable<boolean | string> {
		return this.nextWebcamback.asObservable();
	}

	imageCroppedBack(event: ImageCroppedEvent) {
		this.croppedImageBack = event.base64;
	}

	imageLoadedBack() {
		this.showCropperback = true;
	}

	fileChangeEvent(event: any): void {
		this.convertFile(event.target.files[0]).subscribe(base64 => {
			if (this.isImage(event.target.files[0].name) === true) {
				this.webcamImagecrop = 'data:image/png;base64,' + base64;
				this.imgcropped = true;
				this.UploadObject = 1;
				this.fileInfo.front = {
					name: event.target.files[0].name,
					type: event.target.files[0].type,
				};
			} else {
				this.message.create('error', 'ประเภทไฟล์รูปภาพไม่ถูกต้อง กรุณาอัพโหลดรูปภาพใหม่อีกครั้ง');
			}
		});
	}

	fileChangeEventBack(event: any): void {
		this.convertFile(event.target.files[0]).subscribe(base64 => {
			if (this.isImage(event.target.files[0].name) === true) {
				this.webcamImagecropBack = 'data:image/png;base64,' + base64;
				this.imgcroppedBack = true;
				this.UploadObject = 1;
				this.fileInfo.back = {
					name: event.target.files[0].name,
					type: event.target.files[0].type,
				};
			} else {
				this.message.create('error', 'ประเภทไฟล์รูปภาพไม่ถูกต้อง กรุณาอัพโหลดรูปภาพใหม่อีกครั้ง');
			}
		});
	}

	isImage(filename: any) {
		var ext = this.toolsService.getExtension(filename);
		switch (ext.toLowerCase()) {
			case 'jpg':
			case 'png':
			case 'jpeg':
				return true;
			default:
				return false;
		}
	}

	async saveCroppedImage() {
		this.ocrToggle = true;
		this.Spinning = true;
		var filename = 'Licence-img' + Math.floor(Math.random() * 100 + 1).toString();
		var link = document.createElement('a');
		document.body.appendChild(link); // for Firefox
		link.setAttribute('href', this.croppedImage);
		link.setAttribute('download', filename);
		const imageBlob = this.convertDataUrlToBlob(this.croppedImage);
		const file = new File([imageBlob], filename, { type: 'image/jpg' });
		const tokenData = this.authService.currentTokenValue;
        const headerDirect = {
            Authorization: 'Bearer ' + tokenData.idToken,
        };
 
        const requestOptions = {
            headers: new HttpHeaders(headerDirect),
        };
        const addressUrl = `${environment.endpointAws}/ocr/sendKeyLicence`;
        let myFormData: any = new FormData();
        myFormData.append('image', file);
        this.http
            .post(addressUrl, myFormData,requestOptions)
			.subscribe(
				res => {
					this.showjson = true;
					this.objres = res;
					if (this.objres.error_msg !== undefined) {
						this.message.create('error', 'รูปภาพไม่ถูกต้อง กรุณาระบุข้อมูล หรือ ถ่ายรูปภาพใหม่อีกครั้ง');
						this.ocrToggle = false;
						this.Spinning = false;
					} else {
						this.contentkey = this.objres.result;
						this.valuenumberlicence = this.objres.result.number_th;
						this.valuetypelicence = this.objres.result.type_th;
						this.valuetypelicence = this.valuetypelicence.replace('รถยนต์', 'รย.');
						this.valuetypelicence = this.valuetypelicence.replace('รถจักรยานยนต์', 'รยย.');
						this.valuetypelicence = this.valuetypelicence.replace('ใบอนุญาตขับ', '');
						this.valuetypelicence = this.valuetypelicence.replace('ใบอนุญาตเป็นผู้ขับรถ', '');
						this.valuenamelicence = this.objres.result.first_name_th + ' ' + this.objres.result.last_name_th;
						this.valueprefixlicence = this.objres.result.title_th;
						this.Spinning = false;
						this._sendCroppedImage.emit({
							cardNumber: this.valuenumberlicence,
							typeCard: this.valuetypelicence,
							prefix: this.valueprefixlicence,
							name: this.valuenamelicence,
							ocrToggle: this.ocrToggle,
						});
					}
				},
				error => {
					if (ErrorHandler) {
						this.showjson = false;
						this.message.create('error', 'รูปภาพไม่ถูกต้อง กรุณาระบุข้อมูล หรือ ถ่ายรูปภาพใหม่อีกครั้ง');
						this.ocrToggle = false;
						this.Spinning = false;
					}
				},
			);
	}

	convertDataUrlToBlob(dataUrl: any): Blob {
		const arr = dataUrl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = window.atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	convertFile(file: File): Observable<string> {
		const result = new ReplaySubject<string>(1);
		const reader = new FileReader();
		reader.readAsBinaryString(file);
		reader.onload = () => result.next(window.btoa(reader.result?.toString() || ''));
		return result;
	}

	saveBlob2aws(position: string, data: any): Observable<HttpEvent<any>> {
		const tokenData = this.authService.currentTokenValue;
		const headerDirect = {
			Authorization: 'Bearer ' + tokenData.idToken,
		};

		const requestOptions = {
			headers: new HttpHeaders(headerDirect),
		};
		const imageBlob = this.convertDataUrlToBlob(position === DriverLicenseTypes.FRONT ? this.croppedImage : this.croppedImageBack);
		const fileName = data.name;
		const file = new File([imageBlob], fileName, { type: imageBlob.type });
		const addressUrl = `${environment.endpointAws}/awsS3/uploadPicture`;
		let myFormData: any = new FormData();
		myFormData.append('file', file);
		myFormData.append('accCallNo', this._currentTask.AccCallNo);
		myFormData.append('fileName', fileName);

		return this.http.request(new HttpRequest('POST', addressUrl, myFormData, requestOptions));
	}

	async getObjectUrls(url: any) {
		const tokenData = this.authService.currentTokenValue;
		const headerDirect = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + tokenData.idToken };
		const requestOptions = {
			headers: new HttpHeaders(headerDirect),
		};
		const addressUrl = `${environment.endpointAws}/awsS3/getObject`;
		const checkResultObservable = this.http.post(addressUrl, { urls: [url] }, requestOptions);
		const checkResult = await lastValueFrom(checkResultObservable);
		return checkResult.toString();
	}

	saveImage(url: any, signedUrl: any, fileName: any, type: string, position: string) {
		this.fileUploadObject = {
			id: Math.floor(Math.random() * 100 + 1).toString(),
			name: fileName,
			url: url,
			signedUrl: signedUrl,
			type: type,
			position,
		};
		if (position == DriverLicenseTypes.FRONT) {
			this._saveImage.emit(this.fileUploadObject);
		} else {
			this._saveImageBack.emit(this.fileUploadObject);
		}
	}
}
