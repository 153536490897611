import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment.localTest';

import { AuthService } from '../../auth/authentication.service';
import { Router } from '@angular/router';
import { error } from 'console';

@Injectable()
export class HttpRouterService {
	constructor(private http: HttpClient, public authService: AuthService, private _router: Router) {}

	backendURLGeneratorAWS(pathname: string) {
		return `${environment.endpointAws}/${pathname}`;
	}

	backendURLGeneratorThaivivat(pathname: string) {
		return `${environment.endpointTvi}/${pathname}`;
	}

	call(method: string, url: string, body = {}): Observable<any> {
		const headerDirect = {
			'Content-Type': 'application/json',
		};

		const requestOptions = {
			headers: new HttpHeaders(headerDirect),
		};

		if (method === 'GET') {
			return this.http.get<any>(url, requestOptions);
		} else if (method === 'POST') {
			return this.http.post<any>(url, body, requestOptions).pipe(
				map(data => {
					return data;
				}),
				catchError(error => this.handleError(error)),
			);
		}
	}

	callWithOutAuth(method: string, url: string, body = {}): Observable<any> {
		if (method === 'GET') {
			return this.http.get<any>(url);
		} else if (method === 'POST') {
			return this.http.post<any>(url, body).pipe(
				catchError(this.handleError),
				map(data => {
					return data;
				}),
			);
		}
	}

	private handleError(error: HttpErrorResponse) {
		if (error.status === 503) {
			this._router.navigate(['/pages/maintenance']);
		}

		return throwError(() => error);
	}
}
