import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { User, Token } from '../model/common/user';
import { environment } from 'src/environments/environment.localTest';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	//public
	public currentUser: Observable<User>;
	public token: Observable<Token>;

	//private
	private currentUserSubject: BehaviorSubject<User>;
	private tokenSubject: BehaviorSubject<Token>;

	@Output() public loading: EventEmitter<any> = new EventEmitter<any>();
	@Output() public updateCurrentTask: EventEmitter<any> = new EventEmitter<any>();
	@Input() _currentTask: any = {};
	constructor(private _http: HttpClient, private _toastrService: ToastrService, public _router: Router) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();
		this.currentUser.subscribe(userSession => {
			// do whin value change
			this.redirectTo(this.currentUserValue);
		});

		this.tokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('token')));
		this.token = this.tokenSubject.asObservable();
		this.token.subscribe(tokenSession => {
			// do whin value change
		});

		this.initial();
	}

	async initial() {
		if (this.currentUserSubject?.value?.firstSignIn) {
			this._router.navigate(['/pages/reset-password']);
		} else {
			if ((this.currentTokenValue?.refreshToken, this.currentUserValue?.username)) {
				this.redirectTo(this.currentUserValue);
			}
		}
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	public get currentTokenValue(): Token {
		return this.tokenSubject.value;
	}

	isAuthenticated(): boolean {
		if (this.currentTokenValue) return true;
		else return false;
	}

	isAuthenticatedUser(): boolean {
		if (this.currentTokenValue) return true;
		else return false;
	}

	redirectTo(userData: User) {
		if (userData) {
			if (userData.firstSignIn) {
				this._router.navigate(['/pages/reset-password']);
			} else if (userData?.isStaySignin === true && userData?.isBeta === true) {
				this._router.navigate(['/user-beta/dashboard']);
			} else {
				this._router.navigate(['/user/dashboard']);
			}
		}
	}

	async login(username: string, password: string) {
		const resp: any = await this._http
			.post<any>(`${environment.endpointAws}/auth/signin`, { username, password })
			.toPromise()
			.catch(error => {
				if (error.status === 503) {
					this._router.navigate(['/pages/maintenance']);
				} else {
					throw error;
				}
			});

		if (resp?.token) {
			localStorage.setItem('token', JSON.stringify(resp.token));
			this.tokenSubject.next(resp.token);

			localStorage.setItem('currentUser', JSON.stringify(resp.userData));
			this.currentUserSubject.next(resp.userData);
			return resp;
		}
		return false;
	}

	async loginWithCookie(payload: any) {
		if (payload?.token && payload?.userData) {
			localStorage.setItem('token', JSON.stringify(payload.token));
			this.tokenSubject.next(payload.token);

			localStorage.setItem('currentUser', JSON.stringify(payload.userData));
			this.currentUserSubject.next(payload.userData);
			return payload;
		}
		return false;
	}

	loginToken(token: string): Observable<any> {
		// const header = {
		// 	Authorization: `Bearer ${refreshToken}`,
		// };

		// const requestOptions = {
		// 	headers: new HttpHeaders(header),
		// };

		return this._http.post<any>(`${environment.endpointAws}/auth/signInWithToken`, { token, createdFrom: 'CLAIM_ON_WEB' }, httpOptions);

		// .catch(error => {
		// 	if (error.status === 503) {
		// 		this._router.navigate(['/pages/maintenance']);
		// 	} else if (error.status === 403 || error.status === 401) {
		// 		this._router.navigate(['/pages/login']);
		// 	}
		// });

		// if (resp?.token) {
		// 	localStorage.setItem('token', JSON.stringify(resp.token));
		// 	this.tokenSubject.next(resp.token);

		// 	localStorage.setItem('currentUser', JSON.stringify(resp.userData));
		// 	this.currentUserSubject.next(resp.userData);
		// 	return resp;
		// }
		// return false;
	}

	logout() {
		console.log('logout');
		// remove user from local storage to log user out
		localStorage.removeItem('currentUser');
		localStorage.removeItem('token');
		// notify
		this.currentUserSubject.next(null);
		this.tokenSubject.next(null);
		this._router.navigate(['/pages/login']);
	}
}
