import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../authentication.service';

@Injectable()
export class AuthUserGuard implements CanActivate {
	constructor(protected authService: AuthService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const pms = this.authService.isAuthenticatedUser();
		if (pms) {
			return pms;
		} else {
			this.router.navigate(['/pages/login']);
			return false;
		}
	}
}

@Injectable()
export class AuthUserBetaGuard implements CanActivate {
	constructor(protected authService: AuthService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const pms = this.authService.isAuthenticatedUser();
		if (pms) {
			return pms;
		} else {
			this.router.navigate(['/pages/login']);
			return false;
		}
	}
}
