import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth/authentication.service';
import { BreakpointService } from 'src/app/shared/service/form-helper/breakpoint.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit, AfterViewInit {
	public loginForm: FormGroup;

	isViewportDesktop = false;
	isLoadingSignOn = false;
	isPasswordVisible = false;
	isLoading = false;
	isLoginFromClaimOnSurveyor = false;
	submitted = false;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private spinner: NgxSpinnerService,
		private breakpointService: BreakpointService,
		private router: Router,
		private cookieService: CookieService,
	) {}

	async ngOnInit() {
		this.breakpointService.isSmallScreen$.subscribe(isSmallScreen => {
			this.isViewportDesktop = !isSmallScreen;
		});

		this.loginForm = this.formBuilder.group({
			username: ['', [Validators.required]],
			password: ['', Validators.required],
		});
	}

	async ngAfterViewInit() {
		if (this.cookieService.check('claim-on')) {
			this.isLoginFromClaimOnSurveyor = true;
			this.authService.loginWithCookie(JSON.parse(this.cookieService.get('claim-on')));
		}
	}

	get f() {
		return this.loginForm.controls;
	}

	gotoForgotPassword() {
		this.router.navigate(['/pages/forgot-password']);
	}

	async onSubmit() {
		this.submitted = true;
		this.isLoadingSignOn = true;
		if (this.loginForm.invalid) {
			this.isLoadingSignOn = false;
			this.spinner.hide();
			return;
		}

		this.spinner.show(undefined, {
			type: 'ball-triangle-path',
			size: 'medium',
			bdColor: 'rgba(0, 0, 0, 0.8)',
			color: '#fff',
			fullScreen: true,
		});
		try {
			const data = await this.authService.login(this.f.username.value, this.f.password.value);
			if (data) {
				this.authService.redirectTo(this.authService.currentUserValue);
				// this.toastrService.success('You have successfully logged in as an user to Claim-on. Now you can start to explore. Enjoy! 🎉', '👋 Welcome!', { closeButton: true });
			}
		} catch (err: any) {
			if (err.status === 400 || err.status === 401) {
				this.loginForm.get('password').setErrors({ wrongauth: true });
				// this.toastrService.error('username or password incorrect', 'Login fail!', { closeButton: true });
			} else {
				this.loginForm.get('password').setErrors({ wrongauth: true });
				// this.toastrService.error('cant connect to server plase contact admin', 'Login fail!', { closeButton: true });
			}
		}
		this.isLoadingSignOn = false;
		this.spinner.hide();
	}
}
