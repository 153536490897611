import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
	selector: 'app-coming-soon',
	templateUrl: './coming-soon.component.html',
	styleUrls: ['./coming-soon.component.sass'],
})
export class ComingSoonComponent implements OnInit {
	//lottie
	lottieSocialOption: AnimationOptions = {
		path: 'assets/lottie/103144-coming-soon-loader.json',
	};

	constructor() {}

	ngOnInit(): void {}

	updateOption() {
		// this.lottieSocialOption = {
		//   ...this.lottieSocialOption,
		//   path: '/assets/lottie/83351-taking-the-duggy-out.json',
		// }
	}

	animationCreated(animationItem: AnimationItem): void {
		//console.log(animationItem);
	}
}
