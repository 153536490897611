import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 
import { takeUntil, first } from 'rxjs/operators';
 
import { HttpRouterService } from './_httpRouterService';
import { lastValueFrom } from 'rxjs';
 
@Injectable()
export class NewCCService {
    constructor(private http: HttpClient, private httpRouterService: HttpRouterService) {}
 
    async getConstantData() {
        const [method, url] = ['GET', this.httpRouterService.backendURLGeneratorAWS('newcc-constant/defaultVariable')];
        const request = this.httpRouterService.call(method, url);
        return lastValueFrom(request);
    }
 
    async getPolicyInfo(accCallNo: string) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/getPolicyInfo'), { AccCallNo: accCallNo }];
        return await this.httpRouterService.call(method, url).pipe(first()).toPromise();
    }
 
    // victimStatusId (0 = ผู้ขับขี่ / 1 = ผู้โดยสาร / 2 = บุคคลภายนอก) accPersonNo รถประกัน  (ID = 0) / คู่กรณี (ID = 1)
    async getVictimCoverage(accCallNo: string, victimStatusId: string, accPersonType: string, injuryLevelId: string) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/getVictimCoverage')];
        return await this.httpRouterService
            .call(method, url, {
                AccCallNo: accCallNo,
                VictimStatusId: victimStatusId,
                AccPersonType: accPersonType,
                InjuryLevelId: injuryLevelId,
            })
            .pipe(first())
            .toPromise();
    }
 
    async getAccidentBefore(accCallNo: string) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/getAccidentBefore')];
        return await this.httpRouterService.call(method, url, { AccCallNo: accCallNo }).pipe(first()).toPromise();
    }
 
    async getAlcoholPercent(ALC_HR: string, ALC_MG: string) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/getAlcoholPercent')];
        return await this.httpRouterService.call(method, url, { ALC_HR: ALC_HR, ALC_MG: ALC_MG }).pipe(first()).toPromise();
    }
 
    async saveData2NewCC(accCallNo: string, userId: string, jsonBody: object) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/saveData2NewCC')];
        return await this.httpRouterService.call(method, url, { AccCallNo: accCallNo, UserId: userId, JSON_Body: jsonBody }).pipe(first()).toPromise();
    }
 
    async getPriceParts(carPartID: string, carDamageLevel: string, carTypeId: string, accCallNo: string, accPersonNo: string) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/getPriceParts')];
        return await this.httpRouterService.call(method, url, { carPartID: carPartID, carDamageLevel: carDamageLevel,carTypeId:carTypeId,accCallNo:accCallNo,accPersonNo:accPersonNo }).pipe(first()).toPromise();
    }
 
    async getCarTypeInsured(accCallno: string) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/getCarTypeInsured')];
        return await this.httpRouterService.call(method, url, { accCallno:accCallno }).pipe(first()).toPromise();
    }
 
    async getCarType(carModel: string) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/getCarType')];
        return await this.httpRouterService.call(method, url, { carModel:carModel }).pipe(first()).toPromise();
    }
 
    async getCarPart(carType: string, accCallNo: string, accPersonNo: string) {
        const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('newcc/getCarPart')];
        return await this.httpRouterService.call(method, url, { carType:carType,accCallNo:accCallNo,accPersonNo:accPersonNo }).pipe(first()).toPromise();
    }
}
 