import { Component, ViewChild, OnInit, Inject, AfterViewInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/shared/service/api/user.service';
import { BreakpointService } from 'src/app/shared/service/form-helper/breakpoint.service';
import { Token, User } from '../../model/common/user';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.sass'],
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
	public resetPasswordForm: FormGroup;
	isPasswordVisible = false;
	isConfirmPasswordVisible = false;
	isFailure: any = NaN;

	tokenResetPassword = '';
	isLoadingSignOn = false;
	passwordVisible = false;
	loading = false;
	submitted = false;

	isViewportDesktop = false;
	isAlreadySend = false;
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;
	public token: Observable<Token>;

	constructor(
		private formBuilder: FormBuilder,
		private breakpointService: BreakpointService,
		private userService: UserService,
		private toastrService: ToastrService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();
		this.currentUser.subscribe(userSession => {
			// do whin value change
		});

		this.activatedRoute.queryParams.subscribe((params: any) => {
			this.tokenResetPassword = params.token;
		});
	}

	async ngOnInit() {
		this.breakpointService.isSmallScreen$.subscribe(isSmallScreen => {
			this.isViewportDesktop = !isSmallScreen;
		});

		this.resetPasswordForm = new FormGroup(
			{
				password: new FormControl('', [formatIsValid, Validators.required]),
				confirmPassword: new FormControl('', [Validators.required]),
			},
			confirmPasswordValidator,
		);
	}

	get f() {
		return this.resetPasswordForm.controls;
	}

	async onSubmit() {
		try {
			this.submitted = true;
			this.loading = true;
			if (!this.resetPasswordForm.invalid) {
				this.isAlreadySend = true;
				const data = await this.userService.resetPassword(this.f.password.value, this.tokenResetPassword || this.currentUserSubject.value.passwordResetToken);
				//this.toastrService.success('You have successfully reset password on ClaimOn. Now you can start to explore. Enjoy! 🎉', 'Success!', { closeButton: true });
				this.isFailure = false;
				this.returnToLogin();
			}
		} catch (err: any) {
			this.isFailure = true;

			// this.toastrService.error(err.message, 'Query error');
		} finally {
			this.loading = false;
		}
	}

	returnToLogin() {
		setTimeout(() => {
			this.router.navigate(['/pages/login']);
		}, 3000);
	}

	ngAfterViewInit() {
		if (this.document.getElementById('my-footer')) {
			this.document.getElementById('my-footer').style.display = 'none';
		}
	}
}

function checkStrongPasswordFormat(password: any, min: any) {
	const regexEnglishOnly = new RegExp(/^[a-zA-Z!@#$%^&*()_+0-9]+$/g);
	const regex = new RegExp(/^(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[0-9])(?=.*[a-z])/g);
	if (password && password.length >= min) {
		const resultEng = regexEnglishOnly.test(password) ? true : false;
		const resultStrongPwd = regex.test(password) ? true : false;
		return resultEng && resultStrongPwd;
	} else {
		return false;
	}
}

export function formatIsValid(control: AbstractControl) {
	const passwordIsStrong = checkStrongPasswordFormat(control.value, 8);
	if (passwordIsStrong) {
		return null;
	}
	return {
		not_strong_pwd: true,
	};
}

export const confirmPasswordValidator = (control: AbstractControl) => {
	return control.value.password === control.value.confirmPassword ? null : { not_matching: true };
};
