import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { UserService } from 'src/app/shared/service/api/user.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.sass'],
})
export class ChangePasswordComponent implements OnInit {
	public resetPasswordForm: FormGroup;

	isLoadingChangePassword: boolean = false;
	isCurrentPasswordVisible = false;
	isPasswordVisible = false;
	isRePasswordVisible = false;
	submitted = false;

	@Input() isVisibleChangePassword = false;

	@Output() _changeVisible = new EventEmitter();

	constructor(private _router: Router, private _toastrService: ToastrService, private _formBuilder: FormBuilder, private userService: UserService) {}

	ngOnInit(): void {
		this.resetPasswordForm = this._formBuilder.group(
			{
				currentPassword: ['', [Validators.required]],
				password: ['', [Validators.required]],
				confirmPassword: ['', [Validators.required]],
			},
			{ validator: CustomValidators.validatePassword },
		);
	}

	get f() {
		return this.resetPasswordForm.controls;
	}

	async changepassword() {
		try {
			this.submitted = true;
			this.isLoadingChangePassword = true;
			if (!this.resetPasswordForm.invalid) {
				await this.userService.changePassword(this.f.currentPassword.value, this.f.password.value);
				this._changeVisible.emit();
				this._toastrService.success('Changepass', 'Password has been changed.');
			}
		} catch (err: any) {
			if (err.status === 400) {
				this._toastrService.error('รหัสผ่านปัจจุบันไม่ถูกต้อง', 'พบข้อผิดพลาด');
			} else {
				this._toastrService.error(err.message, 'Error please contact admin.');
			}
		} finally {
			this.isLoadingChangePassword = false;
		}
	}
}

export class CustomValidators {
	static validatePassword(control: AbstractControl) {
		const passwordControl = control.get('password');
		const confirmControl = control.get('confirmPassword');
		const password = passwordControl.value;
		const confirmPassword = confirmControl.value;
		passwordControl.setErrors(null);

		const passwordIsStrong = checkStrongPasswordFormat(password, 8);

		if (compare(password, confirmPassword)) {
			passwordControl.setErrors({ not_matching: true });
		} else if (!passwordIsStrong) {
			passwordControl.setErrors({ not_strong_pwd: true });
		}
	}
}

function compare(password: string, confirmPassword: string) {
	return password !== confirmPassword && confirmPassword !== '';
}

function checkStrongPasswordFormat(password: any, min: any) {
	const regexEnglishOnly = new RegExp(/^[a-zA-Z!@#$%^&*()_+0-9]+$/g);
	const regex = new RegExp(/^(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[0-9])(?=.*[a-z])/g);
	if (password && password.length >= min) {
		const resultEng = regexEnglishOnly.test(password) ? true : false;
		const resultStrongPwd = regex.test(password) ? true : false;
		return resultEng && resultStrongPwd;
	} else {
		return false;
	}
}
