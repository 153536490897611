import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { takeUntil, first } from 'rxjs/operators';

import { HttpRouterService } from './_httpRouterService';

@Injectable()
export class MclaimService {
	constructor(private http: HttpClient, private httpRouterService: HttpRouterService) {}

	// get new task list (notification number)
	async getTaskNew(surveyorId: string) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('mclaim/getTaskNew')];
		return await this.httpRouterService.call(method, url, { SurveyorId: surveyorId }).pipe(first()).toPromise();
	}

	// get task history (notification number)
	async getTaskHistory(surveyorId: string) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('mclaim/getTaskSuccess')];
		return await this.httpRouterService.call(method, url, { SurveyorId: surveyorId }).pipe(first()).toPromise();
	}

	async getAccident(AccCallNo: string) {
		const [method, url] = ['GET', this.httpRouterService.backendURLGeneratorAWS('mclaim/getAccident/' + AccCallNo)];
		return await this.httpRouterService.call(method, url).pipe(first()).toPromise();
	}
}
