export const ObjOcrApiConfig: any = {
	GLOBAL_MIN_DISTANCE: 0.5,
	driver_license_type_old: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'ชนิด',
		value: '.*',
	},
	driver_license_type_new: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'ใบอนุญาตขับรถยนต์',
		value: '.*',
	},
	issued_id: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'ฉบับที่',
		value: '[0-9]{8}',
	},
	citizen_id: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'เลขประจำตัวประชาชน',
		value: '([0-9-:. ]*)$',
	},
	driver_name_th_ms: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'น.ส.',
		value: '(([\u0E00-\u0E7F]{2,} [\u0E00-\u0E7F]{3,})(?![\u0E00-\u0E7F]{2,} [\u0E00-\u0E7F]{3,}))',
		return_whitespace: 1,
	},
	driver_name_th_mr: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'นาย',
		value: '(([\u0E00-\u0E7F]{2,} [\u0E00-\u0E7F]{3,})(?![\u0E00-\u0E7F]{2,} [\u0E00-\u0E7F]{3,}))',
		return_whitespace: 1,
	},
	driver_name_th_ms_2: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'นาง',
		value: '(([\u0E00-\u0E7F]{2,} [\u0E00-\u0E7F]{3,})(?![\u0E00-\u0E7F]{2,} [\u0E00-\u0E7F]{3,}))',
		return_whitespace: 1,
	},
	dob_date: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'เกิดวันที่',
		value: '.*',
		return_whitespace: 1,
	},
	expired_date: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'วันสิ้นอายุ',
		value: '.*',
		return_whitespace: 1,
	},
	issue_date_en: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'Issue Date',
		value: '^([0-9]{2}[ ]{0,1}[a-zA-Z.,]*[ ]{0,1}[0-9]{4})',
		return_whitespace: 1,
	},
	expire_date_en: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'Expiry date',
		value: '([0-9]{2}[ ]{0,1}[a-zA-Z.,]*[ ]{0,1}[0-9]{4})$',
		return_whitespace: 1,
	},
	driver_name: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE', { BOTTOM: 1 }],
		key: 'Name',
		value: '(([A-Z,(/]* [A-Z,(/]{1,})(?![A-Z,(/]* [A-Z,(/]{1,}))',
		return_whitespace: 1,
	},
	driver_name_en_mr: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE'],
		key: 'MR. ',
		value: '(([A-Z,(/]* [A-Z,(/]{1,})(?![A-Z,(/]* [A-Z,(/]{1,}))',
		return_whitespace: 1,
	},
	driver_name_en_ms: {
		loc: ['LEFT_TO_RIGHT', 'IN_SAME_LINE', { BOTTOM: 1 }],
		key: 'MISS',
		value: '(([A-Z,(/]* [A-Z,(/]{1,})(?![A-Z,(/]* [A-Z,(/]{1,}))',
		return_whitespace: 1,
	},
};
