export const WITNESS_STATUS = {
	DRIVER: 'ผู้ขับขี่',
	PASSENGER: 'ผู้โดยสาร',
	THIRD_PARTY: 'บุคคลภายนอก',
};

export const WITNESS_STATUS_TYPE = {
	INSURED: 'รถประกัน',
	PARTIES: 'รถคู่กรณี',
};

export const INQ_CHECK_LEAVE = {
	YES: 'ออก',
	NO: 'ไม่ออก',
};

export const CASUALTYGROUP = {
	DRIVER: '0',
	PASSENGER: '1',
	THIRD_PARTY: '2',
};

export const CASUALTYTYPE = {
	INSURED: 'รถประกัน',
	PARTY: 'รถคู่กรณี',
};

export const CORPNAME = {
	COMPANY_THAIVIVAT: '01578',
	COMPANY_ฺAXA: '01203',
	COMPANY_ALLIANZ: '01856',
	COMPANY_ฺBANGKOK: '01245',
	COMPANY_FPG: '01967',
};

export const CARBODY = {
	CARBODY_TRUCK: '003',
};

export const ERROR_CODE = {
	USER_NOT_FOUND: 'USER_NOT_FOUND',
	REQUEST_OTP_FOR_SURVEYOR_ONLY: 'REQUEST_OTP_FOR_SURVEYOR_ONLY',
};
