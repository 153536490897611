import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../authentication.service';
import { Token } from '../../model/common/user';

const TOKEN_HEADER_KEY = 'Authorization'; // for Spring Boot back-end
// const TOKEN_HEADER_KEY = 'x-access-token'; // for Node.js Express back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<Token>(null);

	constructor(private authService: AuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
		let authReq = req;
		const token = JSON.parse(localStorage.getItem('token'));

		if (token?.idToken != null) {
			authReq = this.addTokenHeader(req, token.idToken);
		}

		return next.handle(authReq).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse && !authReq.url.includes('pages/login') && error.status === 401) {
					return this.handle401Error(authReq, next);
				}
				return throwError(() => error);
			}),
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			const token = JSON.parse(localStorage.getItem('token'));

			if (token)
				return this.authService.loginToken(token?.refreshToken).pipe(
					switchMap((resp: any) => {
						this.isRefreshing = false;
						localStorage.setItem('token', JSON.stringify(resp.token));
						this.refreshTokenSubject.next(resp.token.idToken);
						return next.handle(this.addTokenHeader(request, resp.token.idToken));
					}),
					catchError(err => {
						this.isRefreshing = false;

						if (err.status === 403) {
							this.authService.logout();
						}
						return throwError(() => err);
					}),
				);
		}

		return this.refreshTokenSubject.pipe(
			filter(token => token !== null),
			take(1),
			switchMap(token => next.handle(this.addTokenHeader(request, token))),
		);
	}

	private addTokenHeader(request: HttpRequest<any>, token: string) {
		/* for Spring Boot back-end */

		return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

		/* for Node.js Express back-end */
		return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
	}
}

export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
