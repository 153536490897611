export interface IStateSelectedImage {
	isNew: boolean;
	progressUpload: number;
}

export interface ISelectedImageDriverLicense {
	frontCard: IStateSelectedImage;
	backCard: IStateSelectedImage;
}

export enum DriverLicenseTypes {
	FRONT = 'FRONT',
	BACK = 'BACK',
}
