<ng-container *ngIf="isTest">
    <div class="wrapper">
        <div class="main-panel">
            <!-- BEGIN : Main Content-->
            <div class="main-content-test">
                <router-outlet class="content"></router-outlet>
            </div>
            <app-footer class="footer"></app-footer>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isTest">
    <div class="wrapper">
        <div class="main-panel">
            <!-- BEGIN : Main Content-->
            <div class="main-content">
                <router-outlet class="content"></router-outlet>
            </div>
        </div>
    </div>
</ng-container>