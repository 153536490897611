import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SvgService } from 'src/app/shared/service/common/svg.service';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
	fetching: { [key: string]: any } = {};

	constructor(
		private router: Router,
		private route: ActivatedRoute,

		// init service for load svg icon
		private svgService: SvgService,
	) {}

	ngOnInit() {
		this.fetching['routerEvent'] = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => window.scrollTo(0, 0));
	}

	ngOnDestroy() {
		for (const key in this.fetching) {
			if (this.fetching.hasOwnProperty(key) && this.fetching[key]) {
				if (key.indexOf('unsubscribe') > -1) {
					this.fetching[key].unsubscribe();
				} else {
					this.fetching[key]();
				}
			}
		}
	}
}
