<nz-modal [(nzVisible)]="_isModalVisible" [nzWidth]="160" [nzMaskClosable]="false" nzClassName="custom-loading-modal"
    [nzCloseIcon]="null" [nzTitle]="null" [nzFooter]="null" nzCentered>
    <ng-container *nzModalContent>
        <div class="lds-ring">
            <div class="bg"></div>
            <div class="loading"></div>
            <div class="loading"></div>
            <div class="loading"></div>
        </div>
        <div class="lds-text">
            {{ message }}
        </div>
    </ng-container>
</nz-modal>