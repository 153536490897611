import { Component, OnInit, Inject, AfterViewInit, Renderer2, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Router, NavigationEnd } from '@angular/router';

import { environment } from 'src/environments/environment.localTest';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-content-layout',
	templateUrl: './content-layout.component.html',
	styleUrls: ['./content-layout.component.sass'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ContentLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
	isTest = true;

	configSub: Subscription;

	public innerWidth: any;

	overlayContent = false;

	constructor(private router: Router, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
		if(environment.production === true) {
			this.isTest = false;
		}
		this.innerWidth = window.innerWidth;
	}

	ngOnInit() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(routeChange => {
			if (this.innerWidth < 1200) {
				this.overlayContent = false;
				this.renderer.removeClass(this.document.body, 'overflow-hidden');
			} else {
				this.overlayContent = true;
			}
		});
	}

	ngAfterViewInit() {}

	ngOnDestroy() {
		//Unsubcribe subscriptions
		if (this.configSub) {
			this.configSub.unsubscribe();
		}
	}
}
