import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class BreakpointService {
	isSmallScreen$ = this.breakpointObserver.observe('(max-width: 767px)').pipe(map(({ matches }) => matches));

	constructor(private breakpointObserver: BreakpointObserver) {}
}
