import { Routes } from '@angular/router';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const TVIWORKFLOW_USER_ROUTES: Routes = [
	{
		path: 'user',
		loadChildren: () => import('src/app/pages/user-pages/user-pages.module').then(m => m.UserPagesModule),
	},
];

export const TVIWORKFLOW_USER_BETA_ROUTES: Routes = [
	{
		path: 'user-beta',
		loadChildren: () => import('src/app/pages/user-pages-beta/user-pages-beta.module').then(m => m.UserPagesBetaModule),
	},
];
