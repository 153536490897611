<nz-layout [ngClass]="{'main-layout-desktop': isViewportDesktop, 'main-layout-mobile': !isViewportDesktop}">
    <nz-content class="login-container">
        <div nz-row class="login-content-wrapper">
            <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="9">
            </div>
            <div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="12" [nzLg]="6">
                <div *ngIf="!isLoginFromClaimOnSurveyor">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div nz-row [nzGutter]="[0, 24]">
                            <div nz-col [nzSpan]="24" class="gutter-row">
                                <img height="32px" src="/assets/img/tvi-claimon/claim-on-banner.png" alt="" />
                            </div>
                            <div nz-col [nzSpan]="24" class="gutter-row">
                                <span class="header-login">ยินดีต้อนรับอีกครั้ง</span>
                                <p class="sub-header secondary">ระบบบันทึกการทำเรื่องเคลมประกันภัย สำหรับพนักงาน
                                </p>
                            </div>
                            <div nz-col [nzSpan]="24" class="gutter-row">
                                <div nz-row>
                                    <div nz-col [nzSpan]="24">
                                        <span nz-typography class="label-14">รหัสประจำตัวพนักงาน</span>
                                        <input formControlName="username" [ngClass]="{ 'btn-invalid': submitted && f.username.errors }" nz-input class="input-login" placeholder="" />
                                        <span *ngIf="submitted && f.username.errors" nz-typography nzType="danger">กรุณาระบุเลขประจำตัวพนักงาน</span>
                                    </div>
                                    <div nz-col [nzSpan]="24" class="col-label mt-cus-24">
                                        <span nz-typography class="label-14">รหัสผ่าน</span>
                                        <span nz-typography><a class="tvi-colour" (click)="gotoForgotPassword()">ลืมรหัสผ่าน?</a></span>
                                    </div>
                                    <div nz-col [nzSpan]="24">
                                        <nz-input-group [nzSuffix]="inputPasswordTemplate" class="input-login" [ngClass]="{ 'btn-invalid': submitted && f.password.errors }">
                                            <input [type]="isPasswordVisible ? 'text' : 'password'" nz-input placeholder="" formControlName="password" />
                                        </nz-input-group>
                                        <ng-template #inputPasswordTemplate>
                                            <span nz-typography><a class="tvi-colour" (click)="isPasswordVisible = !isPasswordVisible">{{isPasswordVisible? 'ซ่อน' : 'แสดง'}}</a></span>
                                            <!-- <span nz-icon [nzType]="isPasswordVisible ? 'eye-invisible' : 'eye'" (click)="isPasswordVisible = !isPasswordVisible"></span> -->
                                        </ng-template>
                                        <span *ngIf="submitted && f.password.errors && !f.password.hasError('wrongauth')" nz-typography nzType="danger">กรุณาระบุรหัสผ่าน</span>
                                        <span *ngIf="submitted && f.password.hasError('wrongauth')" nz-typography nzType="danger">รหัสประจำตัวพนักงานหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง</span>
                                    </div>
                                </div>
                            </div>
                            <div nz-col [nzSpan]="24" class="gutter-row">
                                <button [disabled]="isLoading" nz-button nzType="primary" class="btn-signin tvi-colour" [nzLoading]="isLoadingSignOn">
                                    <span>เข้าใช้งาน</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8">
            </div>
        </div>
    </nz-content>
</nz-layout>