import { OnInit } from '@angular/core';
import { Component, HostBinding } from '@angular/core';
import { FileService } from '../../service/fileService';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	//Variables
	fileContents = '';
	constructor(private fileService: FileService) {}
	currentDate: Date = new Date();

	ngOnInit() {
		const filePath = 'assets/.version';
		this.fileService.getFileContents(filePath).subscribe(contents => (this.fileContents = contents));
	}
}
