<nz-layout [ngClass]="{'main-layout-desktop': isViewportDesktop, 'main-layout-mobile': !isViewportDesktop}" *ngIf="isAlreadySend === false && !isRequestOtp">
    <nz-content class="login-container">
        <div nz-row class="login-content-wrapper">
            <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="9">
            </div>
            <div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="10" [nzLg]="6">
                <div nz-row [nzGutter]="[0, 24]">
                    <div nz-col [nzSpan]="24" class="gutter-row">
                        <img height="32px" src="/assets/img/tvi-claimon/claim-on-banner.png" alt="" />
                    </div>
                    <div class="gutter-row">
                        <h1>ลืมรหัสผ่าน</h1>
                    </div>
                    <div nz-col [nzSpan]="24">
                        <p class="sub-header secondary">กรุณาระบุข้อมูลเพื่อใช้ในการรีเซ็ตรหัสผ่าน</p>
                    </div>
                    <div nz-col [nzSpan]="24" class="gutter-row">
                        <nz-segmented [nzBlock]="true" [ngModelOptions]="{standalone: true}"  [nzSize]="'large'" [ngModel]="selectedIndex" [nzOptions]="['อีเมล','เบอร์โทรศัพท์']"  (ngModelChange)="handleIndexChange($event)"></nz-segmented>
                    </div>
                </div>
                <div nz-col [nzSpan]="24" class="gutter-row">
                    <form *ngIf="selectedIndex === 0" [formGroup]="forgetUserForm" (ngSubmit)="onSubmit()">
                        <div nz-row [nzGutter]="[0, 24]">
                            
                            <div nz-col [nzSpan]="24" class="gutter-row">
                                <span nz-typography class="label-14">อีเมล</span>
                                <nz-input-group class="input-group-suffix" nzAddOnAfter="@thaivivat.co.th" [nzStatus]="submitted && f.email.errors ? 'error': ''">
                                    <input formControlName="email" [ngClass]="{ 'btn-invalid': submitted && f.email.errors }" nz-input class="input-default" placeholder="" />
                                </nz-input-group>
                                <span *ngIf="submitted && f.email.hasError('required')" nz-typography nzType="danger">กรุณาระบุอีเมล</span>
                                <span *ngIf="submitted && f.email.hasError('email')" nz-typography nzType="danger">กรุณาระบุอีเมลให้ถูกต้อง</span>
                            </div>
                            <div nz-col [nzSpan]="24" class="gutter-row mt-4">
                                <button [disabled]="loading" nz-button nzType="primary" class="btn-signin tvi-colour" [nzLoading]="isLoadingSignOn">
                                    <span>ส่ง</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <form *ngIf="selectedIndex === 1"[formGroup]="forgetUserFormOtp" (ngSubmit)="onSubmitRequestOtp()">
						<div nz-row [nzGutter]="[0, 24]">
							<div nz-col [nzSpan]="24" class="gutter-row">
								<span nz-typography class="label-14">เบอร์โทร</span>
								<input
									formControlName="phone"
									[ngClass]="{ 'btn-invalid': otpSubmitted && fOtp.phone.errors }"
									nz-input
									class="input-default"
									placeholder=""
									maxlength="10" />
								<span *ngIf="otpSubmitted && fOtp.phone.hasError('required')" nz-typography nzType="danger">กรุณาระบุเบอร์โทร</span>
								<span *ngIf="(otpSubmitted && fOtp.phone.hasError('minlength')) || fOtp.phone.hasError('maxlength')" nz-typography nzType="danger"
									>กรุณาระบุเบอร์โทรให้ครบ 10 หลัก</span
								>            
								<span *ngIf="otpSubmitted && fOtp.phone.hasError('wrongauth')" nz-typography nzType="danger">ไม่พบข้อมูลผู้ใช้งานในระบบ กรุณาติดต่อผู้ดูแลระบบ หรือ หัวหน้าฝ่ายของท่าน</span>
								<span *ngIf="otpSubmitted && fOtp.phone.hasError('surveyoronly')" nz-typography nzType="danger">เฉพาะพนักงาน outsource เท่านั้น</span>
								<span *ngIf="otpSubmitted && fOtp.phone.hasError('other')" nz-typography nzType="danger">{{ messageError || '' }}</span>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row mt-4">
								<button [disabled]="loading" nz-button nzType="primary" class="btn-signin tvi-colour" [nzLoading]="isLoadingSignOn">
									<span>ขอ OTP</span>
								</button>
							</div>
						</div>
					</form>
                </div>
                <div nz-row [nzGutter]="[0, 24]">
                    <div nz-col [nzSpan]="24" class="gutter-row" [align]="'center'">
                        <span nz-typography><a class="tvi-colour label-16-regular" (click)="gotoLogin()">กลับไปหน้าลงชื่อเข้าใช้งาน</a></span>
                    </div>
                </div>
            </div>
        </div>
    </nz-content>
</nz-layout>

<nz-layout class="main-layout" *ngIf="isAlreadySend === true">
    <nz-header [ngClass]="{'login-header-desktop': isViewportDesktop, 'login-header-mobile': !isViewportDesktop}"></nz-header>
    <nz-content class="login-container">
        <div nz-row class="login-content-wrapper" [nzGutter]="16">
            <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8">
            </div>

            <div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="12" [nzLg]="8">

                <div nz-row [nzGutter]="[16, 10]">
                    <div nz-col nzSpan="24" class="set-center">
                        <img width="136px" height="112px" src="/assets/img/tvi-claimon/sendtomail.png" alt="" />
                    </div>
                    <div nz-col nzSpan="24" class="set-center">
                        <h1>เช็กอีเมลของคุณ</h1>
                    </div>
                    <div nz-col nzSpan="24" class="set-center">
                        <p class="sub-header secondary">เราได้ส่งคำแนะนำในการรีเซ็ตรหัสไปยัง<br />อีเมลของคุณที่</p>
                    </div>
                    <div nz-col nzSpan="24" class="set-center">
                        <p class="sub-header">{{ emailPreview }}</p>
                    </div>
                    <div nz-col nzSpan="24" class="set-center">
                        <p class="height-45 tvi-colour sub-header" style="cursor: pointer;" (click)="isAlreadySend = false; forgetUserForm.reset(); submitted = false">นี่ไม่ใช่อีเมลฉัน?</p>
                    </div>
                </div>

                <div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8">
                </div>
            </div>
        </div>
    </nz-content>
</nz-layout>
<nz-layout [ngClass]="{ 'main-layout-desktop': isViewportDesktop, 'main-layout-mobile': !isViewportDesktop }" *ngIf="isRequestOtp">
	<nz-content class="login-container">
		<div nz-row class="login-content-wrapper">
			<div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8"></div>

			<div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="12" [nzLg]="8">
				<div>
					<form [formGroup]="otpVerifyForm" (ngSubmit)="onSubmitVerifyOtp()">
						<div nz-row [nzGutter]="[0, 24]" nzJustify="center">
							<div nz-col [nzSpan]="24">
								<div nz-row nzJustify="center"><img height="112px" src="/assets/img/tvi-claimon/inbox.png" alt="" /></div>
							</div>
							<div nz-col [nzSpan]="16">
								<h1 style="text-align: center; margin-bottom: 8px">เช็กข้อความของคุณ</h1>
								<p class="sub-header secondary" style="text-align: center; margin-bottom: 0">
									เราได้ส่งรหัส OTP เพื่อยืนยันขอรีเซ็ตรหัสไปยัง ข้อความในโทรศัพท์ของคุณ
								</p>
							</div>
							<div nz-col [nzSpan]="16">
								<p class="sub-header" style="text-align: center; margin-bottom: 0">{{ fOtp.phone.value }}</p>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row">
								<div nz-row nzJustify="center">
									<ng-otp-input #ngOtpInput [formCtrl]="$any(fOtpVerify.otp)" (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
								</div>
								<div class="text-center" style="margin-top: 8px">
									<span
										*ngIf="(verifySubmitted && fOtpVerify.otp.hasError('wrongauth')) || (verifySubmitted && fOtpVerify.otp.hasError('required'))"
										nz-typography
										nzType="danger"
										>ระบุไม่ถูกต้อง</span
									>
								</div>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row mt-4">
								<button [disabled]="loading" nz-button nzType="primary" class="btn-signin tvi-colour" [nzLoading]="isLoadingSignOn">
									<span>ยืนยัน</span>
								</button>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row mt-4 text-center">
								<button nz-button nzSize="large" nzType="text" class="tvi-colour" (click)="onGoToInputNumber()">นี่ไม่ใช่เบอร์โทรฉัน ?</button>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8"></div>
		</div>
	</nz-content>
</nz-layout>
