<nz-modal nzClassName="id-card-modal" nzWidth="640px" [(nzVisible)]="_modalCameraVisible" [nzTitle]="UploadObject===0 ? 'ถ่ายรูปบัตรประชาชน':'ครอปรูปบัตร'" nzOkText="ครอปรูปนี้" nzCancelText="ยกเลิก" (nzOnOk)="handleOk()" (nzOnCancel)="handleCancel()">
    <ng-container class="modal-container" *nzModalContent>
        <div class="image-container" *ngIf="showimagecontainer">
            <webcam (imageCapture)="handleImage($event)" [trigger]="triggerObservable" *ngIf="showWebcam"
            [allowCameraSwitch]="allowCameraSwitch" mirrorImage="never" [switchCamera]="nextWebcamObservable"
            [imageQuality]="1" [captureImageData]="true" capture="environment" (initError)="handleInitError($event)"
            [videoOptions]="{width: {min: 1280, ideal: 1920}, height: {min: 720, ideal: 1080}}">
        </webcam>
            <img class="img-mockup"  src="/assets/img/ocr/idcardframe.png" alt="" />
        </div>
        <div class="img-canvas">
            <canvas style="width: 100%; display: none;" id="canvas"></canvas>
        </div>
        <div class="btns" *ngIf="showimagecontainer">
            <button class="btnss" (click)="triggerSnapshot()">ถ่ายรูปบัตร</button>
        </div>
        <input type="file" *ngIf="showfileupload" (change)="fileChangeEvent($event)"/>
       <image-cropper *ngIf="imgcropped" [imageBase64]="_webcamImagecrop" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
                format="jpeg" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"></image-cropper>
    </ng-container>
</nz-modal>
