import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.sass'],
})
export class ErrorComponent implements OnInit {
	//lottie
	lottieSocialOption: AnimationOptions = {
		path: 'assets/lottie/77620-404-website-error-animation.json',
	};

	constructor() {}

	ngOnInit(): void {}

	updateOption() {
		// this.lottieSocialOption = {
		//   ...this.lottieSocialOption,
		//   path: '/assets/lottie/83351-taking-the-duggy-out.json',
		// }
	}

	animationCreated(animationItem: AnimationItem): void {
		//console.log(animationItem);
	}
}
