import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { takeUntil, first } from 'rxjs/operators';

import { HttpRouterService } from './_httpRouterService';

@Injectable()
export class UserService {
	constructor(private http: HttpClient, private httpRouterService: HttpRouterService) {}

	async userDetail(username: String) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('auth/getUserData')];
		const body = {
			username: username || '',
		};
		return await this.httpRouterService.call(method, url, body).pipe(first()).toPromise();
	}

	async forgotPassword(email: String) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('auth/forgot-password')];
		const body = {
			email: email || '',
		};
		return await this.httpRouterService.callWithOutAuth(method, url, body).pipe(first()).toPromise();
	}
	async forgotPasswordForSurveyor(phone: String) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('auth/forgot-password')];
		const body = {
			phone: phone || '',
		};
		return await this.httpRouterService.callWithOutAuth(method, url, body).pipe(first()).toPromise();
	}
	async verifyOtp(phone: String, sessionId: string, otp: string) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('auth/verify-otp')];
		const body = {
			phone: phone || '',
			sessionId: sessionId || '',
			code: otp || '',
		};
		return await this.httpRouterService.callWithOutAuth(method, url, body).pipe(first()).toPromise();
	}

	async resetPassword(password: String, token: string) {
		const urlWithToken = 'auth/reset-password?token=' + token;
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS(urlWithToken)];
		const body = {
			password: password || '',
		};
		return await this.httpRouterService.callWithOutAuth(method, url, body).pipe(first()).toPromise();
	}

	async changePassword(password: String, newPassword: string) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('user/changePassword')];
		const body = {
			password: password || '',
			newPassword: newPassword || '',
		};
		return await this.httpRouterService.call(method, url, body).pipe(first()).toPromise();
	}
}
