<nz-modal nzClassName="driver-licence-modal" nzWidth="640px" [(nzVisible)]="_modalCameraVisible" [nzTitle]="UploadObject===0 ? 'ถ่ายรูปใบขับขี่':'ครอปรูปบัตร'" nzOkText="ครอปรูปนี้" nzCancelText="ยกเลิก" (nzOnOk)="handleOk()" (nzOnCancel)="handleCancel()">
    <ng-container class="modal-container" *nzModalContent>
        <div class="image-container" *ngIf="showimagecontainer">
            <webcam (imageCapture)="handleImage($event)" [trigger]="triggerObservable" *ngIf="showWebcam"
            [allowCameraSwitch]="true" mirrorImage="never" [switchCamera]="nextWebcamObservable"
            [imageQuality]="1" [captureImageData]="true" capture="environment" (initError)="handleInitError($event)"
            [videoOptions]="{width: {min: 1280, ideal: 1920}, height: {min: 720, ideal: 1080}}">
        </webcam>
            <img class="img-mockup"  src="/assets/img/ocr/driverlicenseframe.png" alt="" />
        </div>
        <div class="img-canvas">
            <canvas style="width: 100%; display: none;" id="canvas"></canvas>
        </div>
        <div class="btns" *ngIf="showimagecontainer">
            <button class="btnss" (click)="triggerSnapshot()">ถ่ายรูปบัตร</button>
        </div>
        <input type="file" *ngIf="showfileupload" (change)="fileChangeEvent($event)"/>
        <div class="img-crop">
            <image-cropper [imageBase64]="webcamImagecrop" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
                format="jpeg" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"></image-cropper>
        </div>
    </ng-container>
</nz-modal>

 <!-- หลังบัตร -->
 <nz-modal nzClassName="driver-licence-modal" nzWidth="640px" [(nzVisible)]="_modalCameraBackVisible" [nzTitle]="UploadObject===0 ? 'ถ่ายรูปใบขับขี่':'ครอปรูปบัตร'" nzOkText="ครอปรูปนี้" nzOkText="ครอปรูปนี้"
 nzCancelText="ยกเลิก" (nzOnOk)="handleOkBack()" (nzOnCancel)="handleCancelBack()">
 <ng-container class="modal-container" *nzModalContent>
   <div class="image-container" *ngIf="showimagecontainerBack">
     <webcam (imageCapture)="handleImageBack($event)" mirrorImage="never" [trigger]="triggerObservableBack"
       *ngIf="showWebcamBack" [allowCameraSwitch]="true" [imageQuality]="1"
       [videoOptions]="{width: {min: 1280, ideal: 1920}, height: {min: 720, ideal: 1080}}"
       [captureImageData]="true">
     </webcam>
   </div>
   <canvas style="width: 100%; display: none;" id="canvasback"></canvas>
   <div class="btns" *ngIf="showimagecontainerBack">
     <button class="btnss" (click)="triggerSnapshotBack()">ถ่ายรูปบัตร</button>
   </div>
   <input type="file" *ngIf="showfileuploadBack" (change)="fileChangeEventBack($event)" />
   <div class="img-crop">
     <image-cropper [imageBase64]="webcamImagecropBack" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
       format="jpeg" (imageCropped)="imageCroppedBack($event)" (imageLoaded)="imageLoadedBack()"></image-cropper>
   </div>
 </ng-container>
</nz-modal>