import { createReducer, on } from '@ngrx/store';
import { actionGetCollectionJsonBodyDetail, actionGetJsonBodyDetail, actionSaveJsonBodyDetail } from './jsonBodyDetail.action';

export const initialState: any = {};

export const jsonBodyDetailReducer = createReducer(
	initialState,
	on(actionGetCollectionJsonBodyDetail, state => {
		return state;
	}),
	on(actionGetJsonBodyDetail, (state, { accCallNo, payload }) => {
		return { ...state, [accCallNo]: payload };
	}),
	on(actionSaveJsonBodyDetail, (state, { accCallNo, payload }) => {
		return { ...state, [accCallNo]: payload };
	}),
);
