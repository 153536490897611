import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class ToolsService {
	constructor(private http: HttpClient) { }

	checkLossGroup(lossCode: number): String {
		let lossGroupStr = '';
		if (lossCode) {
			if (lossCode < 200) {
				lossGroupStr = 'รถประกันเป็นฝ่ายผิด';
			} else if (lossCode < 300) {
				lossGroupStr = 'รถประกันเป็นฝ่ายถูก';
			} else if (lossCode < 400) {
				lossGroupStr = 'ยังไม่ทราบผลคดี';
			} else if (lossCode < 500) {
				lossGroupStr = 'ไม่มีคู่กรณี';
			}
		}

		return lossGroupStr;
	}

	checkLostCode(lossCode: number, LossCodeList: Array<[]>): String {
		let lossCodeDesc = null;
		LossCodeList.forEach((lossItem: any) => {
			if (+lossItem.CauseId === lossCode) {
				lossCodeDesc = lossItem.CauseDesc;
			}
		});
		return lossCodeDesc;
	}

	setFormatDateGeneral(dateTime: Date, type: String) {
		let dd: any = dateTime.getDate();
		let mm: any = dateTime.getMonth() + 1;
		const yyyy: any = dateTime.getFullYear();

		let hh: any = dateTime.getHours();
		let min: any = dateTime.getMinutes();
		let sec: any = dateTime.getSeconds();

		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		if (hh < 10) {
			hh = '0' + hh;
		}
		if (min < 10) {
			min = '0' + min;
		}
		if (sec < 10) {
			sec = '0' + sec;
		}

		if (type === 'from') {
			const dateFormat = yyyy + '-' + mm + '-' + dd + ' ' + '00' + ':' + '00' + ':' + '00';
			return dateFormat;
		} else if (type === 'onlyDate') {
			const dateFormat = yyyy + '-' + mm + '-' + dd;
			return dateFormat;
		} else if (type === 'dateTime') {
			const dateFormat = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + sec;
			return dateFormat;
		} else {
			const dateFormat = yyyy + '-' + mm + '-' + dd + ' ' + '23' + ':' + '59' + ':' + '59';
			return dateFormat;
		}
	}

	setFormatDateTh(dateTime: Date) {
		let dd: any = dateTime.getDate();
		let mm: any = dateTime.getMonth() + 1;
		let yyyy: any = dateTime.getFullYear();

		let hh: any = dateTime.getHours();
		let min: any = dateTime.getMinutes();
		let sec: any = dateTime.getSeconds();

		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		} else {
			mm = '' + mm;
		}
		if (hh < 10) {
			hh = '0' + hh;
		}
		if (min < 10) {
			min = '0' + min;
		}
		if (sec < 10) {
			sec = '0' + sec;
		}

		switch (mm) {
			case '01': {
				mm = 'ม.ค.';
				break;
			}
			case '02': {
				mm = 'ก.พ.';
				break;
			}
			case '03': {
				mm = 'มี.ค.';
				break;
			}
			case '04': {
				mm = 'เม.ย.';
				break;
			}
			case '05': {
				mm = 'พ.ค.';
				break;
			}
			case '06': {
				mm = 'มิ.ย.';
				break;
			}
			case '07': {
				mm = 'ก.ค.';
				break;
			}
			case '08': {
				mm = 'ส.ค.';
				break;
			}
			case '09': {
				mm = 'ก.ย.';
				break;
			}
			case '10' || 10: {
				mm = 'ต.ค.';
				break;
			}
			case '11' || 11: {
				mm = 'พ.ย.';
				break;
			}
			case '12' || 12: {
				mm = 'ธ.ค.';
				break;
			}
		}

		yyyy = yyyy + 543;

		const dateFormat = dd + ' ' + mm + ' ' + yyyy;
		return dateFormat;
	}

	setFormatDateTimeTh(dateTime: Date) {
		let dd: any = dateTime.getDate();
		let mm: any = dateTime.getMonth() + 1;
		let yyyy: any = dateTime.getFullYear();

		let hh: any = dateTime.getHours();
		let min: any = dateTime.getMinutes();
		let sec: any = dateTime.getSeconds();

		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		} else {
			mm = '' + mm;
		}
		if (hh < 10) {
			hh = '0' + hh;
		}
		if (min < 10) {
			min = '0' + min;
		}
		if (sec < 10) {
			sec = '0' + sec;
		}

		switch (mm) {
			case '01': {
				mm = 'ม.ค.';
				break;
			}
			case '02': {
				mm = 'ก.พ.';
				break;
			}
			case '03': {
				mm = 'มี.ค.';
				break;
			}
			case '04': {
				mm = 'เม.ย.';
				break;
			}
			case '05': {
				mm = 'พ.ค.';
				break;
			}
			case '06': {
				mm = 'มิ.ย.';
				break;
			}
			case '07': {
				mm = 'ก.ค.';
				break;
			}
			case '08': {
				mm = 'ส.ค.';
				break;
			}
			case '09': {
				mm = 'ก.ย.';
				break;
			}
			case '10' || 10: {
				mm = 'ต.ค.';
				break;
			}
			case '11' || 11: {
				mm = 'พ.ย.';
				break;
			}
			case '12' || 12: {
				mm = 'ธ.ค.';
				break;
			}
		}

		yyyy = yyyy;

		const dateFormat = dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + min;
		return dateFormat;
	}

	convertDataUrlToBlob(dataUrl: any): Blob {
		const arr = dataUrl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = window.atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	convertFile(file: File): Observable<string> {
		const result = new ReplaySubject<string>(1);
		const reader = new FileReader();
		reader.readAsBinaryString(file);
		reader.onload = event => result.next(window.btoa(reader.result?.toString() || ''));
		return result;
	}

	makeRandom(lengthOfCode = 10) {
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
		let text = '';
		for (let i = 0; i < lengthOfCode; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}

	getExtension(filename: any) {
		return filename.split('.').pop();
	}
}
