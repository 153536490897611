<nz-modal [nzWrapClassName]="'modal-bg'" [nzClassName]="'modal-change-password'" [(nzVisible)]="isVisibleChangePassword" nzTitle="เปลี่ยนรหัสผ่าน" nzOkText="ยืนยัน" (nzOnCancel)="_changeVisible.emit()" nzCloseIcon="ng-zorro:close">
    <ng-container *nzModalContent>
        <form [formGroup]="resetPasswordForm">
            <div nz-row>
                <div nz-col [nzSpan]="24">
                    <span nz-typography>รหัสผ่านปัจจุบัน</span>
                    <nz-input-group [nzSuffix]="inputCurrentPasswordTemplate" class="input-login" [ngClass]="{ 'btn-invalid': submitted && f.currentPassword.errors }">
                        <input [type]="isCurrentPasswordVisible ? 'text' : 'password'" nz-input formControlName="currentPassword" />
                    </nz-input-group>
                    <ng-template #inputCurrentPasswordTemplate>
                        <span class="tvi-colour" style="cursor: pointer;" (click)="isCurrentPasswordVisible = !isCurrentPasswordVisible">{{isCurrentPasswordVisible? 'ซ่อน' : 'แสดง'}}</span>
                    </ng-template>
                    <span *ngIf="submitted && f.currentPassword.hasError('required')" nz-typography nzType="danger">กรุณาระบุ</span>
                </div>
            </div>
            <div nz-row class="mt-cus-24">
                <div nz-col [nzSpan]="24">
                    <span nz-typography>รหัสผ่านใหม่</span>
                    <nz-input-group [nzSuffix]="inputPasswordTemplate" class="input-login" [ngClass]="{ 'btn-invalid': submitted && f.password.errors }">
                        <input [type]="isPasswordVisible ? 'text' : 'password'" nz-input formControlName="password" />
                    </nz-input-group>
                    <ng-template #inputPasswordTemplate>
                        <span class="tvi-colour" style="cursor: pointer;" (click)="isPasswordVisible = !isPasswordVisible">{{isPasswordVisible? 'ซ่อน' : 'แสดง'}}</span>
                    </ng-template>
                    <span *ngIf="submitted && f.password.hasError('required')" nz-typography nzType="danger">กรุณาระบุ </span>
                    <span *ngIf="submitted && f.password.hasError('not_matching')" nz-typography nzType="danger">รหัสผ่านใหม่ไม่ตรงกัน </span>
                    <span *ngIf="submitted && !f.password.hasError('required') && !f.password.hasError('not_matching') && f.password.hasError('not_strong_pwd')" nz-typography nzType="danger">ต้องมีตัวอักษรตัวเลข และตัวอักษรพิเศษ [A-Z a-z 123 ~!@#$%^&*()_+] และจำนวนตัวอักษรต้องไม่น้อยกว่า 8 ตัว</span>
                </div>
            </div>
            <div nz-row class="mt-cus-24">
                <div nz-col [nzSpan]="24">
                    <span nz-typography>ยืนยันรหัสผ่านใหม่</span>
                    <nz-input-group [nzSuffix]="inputRePasswordTemplate" class="input-login" [ngClass]="{ 'btn-invalid': submitted && f.confirmPassword.errors }">
                        <input [type]="isRePasswordVisible ? 'text' : 'password'" nz-input formControlName="confirmPassword" />
                    </nz-input-group>
                    <ng-template #inputRePasswordTemplate>
                        <span class="tvi-colour" style="cursor: pointer;" (click)="isRePasswordVisible = !isRePasswordVisible">{{isRePasswordVisible? 'ซ่อน' : 'แสดง'}}</span>
                    </ng-template>
                    <span *ngIf="submitted && f.confirmPassword.hasError('required')" nz-typography nzType="danger">กรุณาระบุ </span>
                    <span *ngIf="submitted && f.password.hasError('not_matching')" nz-typography nzType="danger">รหัสผ่านใหม่ไม่ตรงกัน</span>
                    <span *ngIf="submitted && !f.password.hasError('required') && !f.password.hasError('not_matching') && f.password.hasError('not_strong_pwd')" nz-typography nzType="danger">ต้องมีตัวอักษรตัวเลข และตัวอักษรพิเศษ [A-Z a-z 123 ~!@#$%^&*()_+] และจำนวนตัวอักษรต้องไม่น้อยกว่า 8 ตัว</span>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *nzModalFooter>
        <button [nzLoading]="isLoadingChangePassword" nz-button nzType="primary" class="btn-signin tvi-colour" (click)="changepassword()">ยืนยัน</button>
    </ng-container>
</nz-modal>