import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { CONTENT_ROUTES } from 'src/app/routes/content-layout.routes';
import { TVIWORKFLOW_USER_ROUTES, TVIWORKFLOW_USER_BETA_ROUTES } from 'src/app/routes/tvi-workflow.routes';

import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { UserLayoutBetaComponent } from './layouts/user-layout-beta/user-layout-beta.component';

import { AuthUserGuard, AuthUserBetaGuard } from './shared/auth/helper/auth.guard';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'pages/login' },
	{
		path: '',
		component: ContentLayoutComponent,
		data: { title: 'content Views', preload: true },
		children: CONTENT_ROUTES,
	},
	{
		path: '',
		component: UserLayoutComponent,
		data: { title: 'user Views' },
		children: TVIWORKFLOW_USER_ROUTES,
		canActivate: [AuthUserGuard],
	},
	{
		path: '',
		component: UserLayoutBetaComponent,
		data: { title: 'user_BETA Views' },
		children: TVIWORKFLOW_USER_BETA_ROUTES,
		canActivate: [AuthUserBetaGuard],
	},
	{
		path: '**',
		redirectTo: 'pages/error',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
