import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep, find, findIndex } from 'lodash';
import { ClaimOnService } from 'src/app/shared/service/api/claimon.service';
import { actionGetTaskSummary, actionSaveTaskSummary } from 'src/app/redux/taskSummary/taskSummary.action';

@Injectable()
export class TaskSummaryService {
	getTaskSummaryState = createFeatureSelector<any>('taskSummary');
	getTaskSummaryLoaded = createSelector(this.getTaskSummaryState, (state: any) => state);
	public loaded$: Observable<boolean> = this.store.pipe(select(this.getTaskSummaryLoaded));
	public initialSummaryInfo: any = {
		insuredInfo: { total: 0, completed: 0 },
		incidentInfo: { total: 0, completed: 0 },
		partiesInfo: { total: 0, completed: 0 },
		casualtyInfo: { total: 0, completed: 0 },
		surveyorInfo: { total: 0, completed: 0 },
		summary: { total: 0, completed: 0, percentage: 0 },
		files: { documents: 0, images: 0, video: 0 },
	};

	private taskSummaryList: any = [];

	constructor(private store: Store<any>, private claimOnService: ClaimOnService) {}

	public loadJson() {
		this.store.dispatch(actionGetTaskSummary());
	}

	public setState(payload: any) {
		this.store.dispatch(actionSaveTaskSummary({ payload }));
	}

	async updateState(accCallNo: string) {
		const summaryInfo = await this.claimOnService.getSummary(accCallNo);
		const nextTaskSummary = cloneDeep(this.taskSummaryList);
		const index = findIndex(nextTaskSummary, { AccCallNo: accCallNo });
		if (index !== -1) {
			nextTaskSummary[index] = { ...nextTaskSummary[index], ...summaryInfo };
		}
		this.setState(nextTaskSummary);
	}

	setTaskSummaryList(taskSummaryList: any) {
		this.taskSummaryList = taskSummaryList;
	}

	getTaskSummaryByAccCallNo(accCallNo: string) {
		let taskSummary = this.initialSummaryInfo;
		const findTaskSummary = find(this.taskSummaryList, (json: any) => json.AccCallNo === accCallNo);
		if (findTaskSummary) {
			taskSummary = findTaskSummary;
		}
		return taskSummary;
	}
}
