// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	hmr: false,
	endpointTvi: 'https://claimon2-thaivivatgateway-uat.thaivivat.co.th/api',
	endpointAws: 'https://claim-on-api.beta.thaivivat.co.th/api',
	googlemapApiKey: 'AIzaSyCNN43Li4vIgvktU003JA13dYI5eOehLWI',
	isOcrAvalible: 'false',
	awsS3BucketUrl: "https://claim-on-beta.s3.ap-southeast-1.amazonaws.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
