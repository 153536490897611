<nz-layout [ngClass]="{ 'main-layout-desktop': isViewportDesktop, 'main-layout-mobile': !isViewportDesktop }" *ngIf="!isRequestOtp">
	<nz-content class="login-container">
		<div nz-row class="login-content-wrapper">
			<div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8"></div>

			<div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="12" [nzLg]="8">
				<div>
					<form [formGroup]="forgetUserForm" (ngSubmit)="onSubmitRequestOtp()">
						<div nz-row [nzGutter]="[0, 24]">
							<div nz-col [nzSpan]="24" class="gutter-row">
								<img height="32px" src="/assets/img/tvi-claimon/claim-on-banner.png" alt="" />
							</div>
							<div class="gutter-row">
								<h1>ลืมรหัสผ่าน</h1>
							</div>
							<div nz-col [nzSpan]="24">
								<p class="sub-header secondary">กรุณาระบุข้อมูลเพื่อใช้ในการรีเซ็ตรหัสผ่าน</p>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row">
								<span nz-typography class="label-14">เบอร์โทร</span>
								<input
									formControlName="phone"
									[ngClass]="{ 'btn-invalid': submitted && f.phone.errors }"
									nz-input
									class="input-default"
									placeholder=""
									maxlength="10" />
								<span *ngIf="submitted && f.phone.hasError('required')" nz-typography nzType="danger">กรุณาระบุเบอร์โทร</span>
								<span *ngIf="(submitted && f.phone.hasError('minlength')) || f.phone.hasError('maxlength')" nz-typography nzType="danger"
									>กรุณาระบุเบอร์โทรให้ครบ 10 หลัก</span
								>
								<span *ngIf="submitted && f.phone.hasError('wrongauth')" nz-typography nzType="danger">ระบุไม่ถูกต้อง</span>
								<span *ngIf="submitted && f.phone.hasError('surveyoronly')" nz-typography nzType="danger">เฉพาะพนักงาน outsource เท่านั้น</span>
								<span *ngIf="submitted && f.phone.hasError('other')" nz-typography nzType="danger">{{ messageError || '' }}</span>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row mt-4">
								<button [disabled]="loading" nz-button nzType="primary" class="btn-signin tvi-colour" [nzLoading]="isLoadingSignOn">
									<span>ขอ OTP</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8"></div>
		</div>
	</nz-content>
</nz-layout>
<nz-layout [ngClass]="{ 'main-layout-desktop': isViewportDesktop, 'main-layout-mobile': !isViewportDesktop }" *ngIf="isRequestOtp">
	<nz-content class="login-container">
		<div nz-row class="login-content-wrapper">
			<div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8"></div>
			<div nz-col class="gutter-row login-content" [nzXs]="22" [nzSm]="18" [nzMd]="12" [nzLg]="8">
				<div>
					<form [formGroup]="forgetUserForm" (ngSubmit)="onSubmitVerifyOtp()">
						<div nz-row [nzGutter]="[0, 24]" nzJustify="center">
							<div nz-col [nzSpan]="24">
								<div nz-row nzJustify="center"><img height="112px" src="/assets/img/tvi-claimon/inbox.png" alt="" /></div>
							</div>
							<div nz-col [nzSpan]="16">
								<h1 style="text-align: center; margin-bottom: 8px">เช็กข้อความของคุณ</h1>
								<p class="sub-header secondary" style="text-align: center; margin-bottom: 0">
									เราได้ส่งรหัส OTP เพื่อยืนยันขอรีเซ็ตรหัสไปยัง ข้อความในโทรศัพท์ของคุณ
								</p>
							</div>
							<div nz-col [nzSpan]="16">
								<p class="sub-header" style="text-align: center; margin-bottom: 0">{{ f.phone.value }}</p>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row">
								<div nz-row nzJustify="center">
									<ng-otp-input #ngOtpInput [formCtrl]="$any(fOtp.otp)" [config]="otpConfig"></ng-otp-input>
								</div>
								<div class="text-center" style="margin-top: 8px">
									<span
										*ngIf="(verifySubmitted && fOtp.otp.hasError('wrongauth')) || (verifySubmitted && fOtp.otp.hasError('required'))"
										nz-typography
										nzType="danger"
										>ระบุไม่ถูกต้อง</span
									>
								</div>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row mt-4">
								<button [disabled]="loading" nz-button nzType="primary" class="btn-signin tvi-colour" [nzLoading]="isLoadingSignOn">
									<span>ยืนยัน</span>
								</button>
							</div>
							<div nz-col [nzSpan]="24" class="gutter-row mt-4 text-center">
								<button nz-button nzSize="large" nzType="text" class="tvi-colour" (click)="isRequestOtp = !isRequestOtp">นี่ไม่ใช่เบอร์โทรฉัน ?</button>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div nz-col class="gutter-row" [nzXs]="1" [nzSm]="3" [nzMd]="6" [nzLg]="8"></div>
		</div>
	</nz-content>
</nz-layout>
